* {
  font-family: 'JetBrains Mono', 'monospace';
}

@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.cdnfonts.com/css/matrix');

.vaultBg {
  background: radial-gradient(circle, rgba(15,206,15,0.03323047969187676) 0%, rgba(8,96,8,0.2769279586834734) 49%, rgba(2,28,2,0.6074601715686274) 100%), url('./assets/vaultBg.png') no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  min-height: 100vh;
  height: max-content;
}


.activeClass {
  color: #0fce0f;
  font-size: 20px;
}

.logo:hover {
  cursor: pointer;
}

.loadingText:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

.loadingText-2:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;

}

.iconsLittle {
  z-index: 100000000;
}

.pepeImg {
  cursor: pointer;
}

.page-loader {
  z-index: 1000000000000000000000000000000000000;
}

@keyframes loading {
  0% {
    width: 0;
  }

  50% {
    width: 50%;
  }

  100% {
    width: 100%;
  }
}


.light {
  background-color: #000000;
  min-height: 100vh;
  height: max-content;
}

::-webkit-scrollbar {
  width: 15px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(127, 241, 51);
}

.bluePill-loader {
  width: 100%;
  height: 100vh;
  background: #272727;
  z-index: 1000000000000000;
  position: fixed;
}

.page-loader {
  width: 100%;
  height: 100vh;
  background: #272727;
  z-index: 1000000000000000;
  position: fixed;
}

.page-loaderNew {
  width: 100%;
  height: 100vh;
  background: #272727;
  z-index: 1000000000000000;
  position: fixed;
}

.page-loader3 {
  width: 100%;
  height: 100vh;
  background: #272727;
  z-index: 1000000000000000;
}

/*.page-loader2 {
  background: #000000;
  z-index: 1000000000000000000000000000;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}*/


.page-loader2 {
  width: 100%;
  height: 100vh;
  background: #272727;
  z-index: 100000;
  position: fixed;
}

.vidCont {
  object-fit: cover;
  width: 100%;
}

.videoBg {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (max-width: 499px) {

  .glitchVaultH {
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-size: 35px;
    color: white;
    text-align: center;
    margin-top: 30vh;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.425);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 100%;
   }
  
  .glitchVaultH::before,
  .glitchVaultH::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .glitchVaultH::before {
    left: 2px;
    text-shadow: -2px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }
  
  .glitchVaultH::after {
    left: -2px;
    text-shadow: -2px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }
  .loadingText-2 {
    text-align: center;
    color: white;
    font-size: 16px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .success-2 {
    text-align: center;
    color: #50ec30;
    font-size: 16px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .error-2 {
    text-align: center;
    color: #fc4741;
    font-size: 16px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .pepeImg {
    width: 120px;
    height: 47px;
    margin-right: 10px;
    margin-left: 5px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #43c923, #287914);
    border-image-slice: 1;
    box-shadow: 0 0 10px #56cc087e;

  }

  .light {
    background-color: #000000;
    min-height: 100vh;
    height: max-content;
  }

  .miniBarMain {
    padding-top: 30px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-between;
    z-index: 1;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .navbar {
    width: 25px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 8px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #c5f8b8 1px solid;
    z-index: 0;

  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
    z-index: 100000000000000000000000000000000000;

  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }

  .bluePillDiv {
    width: 100%;
    height: 100vh;
    background: #272727;
    z-index: 1000000000000000;
    position: fixed;
  }

  .loading2 {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    z-index: 1000000000000000000000000000000000000;
    position: fixed;

  }

  .page-loader .txt {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 50%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    font-size: 20px;
    margin-left: 35%;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
  }

  .page-loaderNew .txt {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 50%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    font-size: 20px;
    margin-left: 35%;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
  }


  .back {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 90%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    margin-left: 1%;
    width: 50px;
    height: 50px;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .back:hover {
    transform: scale(1.2);
  }

  .spinner {
    position: relative;
    top: 35%;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #0000009a;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #00000000;
    z-index: 1000000;
    display: none;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #000000;
    z-index: 1000000;
    display: none;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers3 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #00000000;
    display: none;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    width: 90%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .rightImg {
    width: 28px;
    height: 28px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    cursor: pointer;
    z-index: 100000000000000000000000000000000000;
  }

  .left {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #3eec12;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    z-index: 0;

  }

  #fontSize:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 16px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #43c923, #287914);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    box-shadow: 0 0 10px #56cc087e;
    z-index: 1000000000;

  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #f0f7da;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .allWrap2 {
    background: url('./assets/anon.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .wlMain {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.07326680672268904) 0%, rgba(0, 0, 0, 0.2721463585434174) 49%, rgba(0, 0, 0, 0.8127626050420168) 100%), url('./assets/dataserver.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  #container2 {
    background-color: #007fc800;
    color: #0fce0f;
    width: 100%;
    opacity: 0.35;
    position: absolute;
    height: 555vh;
  }

  .light2 {
    height: 555vh;

  }

  .cont {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    background-size: cover;
  }

  .cont2 {
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;

      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;

      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }
  }

  .introduction {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .logo {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    z-index: 5;

  }

  .logoDiv {
    display: flex;
    justify-content: center;

  }

  .title {
    font-size: 30px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
    font-family: 'Matrix', sans-serif;
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 20px;
    color: white;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    letter-spacing: 10px;
    font-family: 'Matrix', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    margin-top: 7%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://img.freepik.com/free-vector/stage-illuminated-by-spotlights_107791-928.jpg?w=740&t=st=1683982217~exp=1683982817~hmac=0f871c85d3f1a98262cfca6dc3abcff569a607560ea2e5da5691c0e9f2fcefd5') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Footer {
    margin-top: 1500px;
    padding-bottom: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transition: 0.5s;
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }


  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 20px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    color: #ffffff;
    text-align: center;
    text-shadow: 0 0 10px #68ff4e;
  }

  .conT2 {
    font-size: 75px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .storyCon {
    font-size: 15px;
    width: 90%;
    color: white;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;

  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    z-index: 1;

  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 28%;
    z-index: 10000000;
    font-weight: 500;
    position: absolute;
    margin-left: -20%;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 50000000000;
    position: absolute;
    margin-top: 28%;
    margin-left: -26%;
    width: 13%;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10;
    margin-top: 2%;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    margin-left: 2%;
    margin-right: 2%;
    border: none;
    padding: 3px;
    z-index: 900000000;
    cursor: pointer;
    background-color: #3a9ae800;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
    display: block;
    width: 40%;
    position: absolute;
  }

  .nftamount {
    color: #ffffff;
    font-size: 1.2rem;
    z-index: 100000000000000000;
    text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #49ff18, 0 0 30px #49FF18, 0 0 40px #49FF18, 0 0 55px #49FF18, 0 0 75px #49ff18;
    position: absolute;
    margin-left: 9%;
    margin-top: -8%;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #24d600 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #252525;
    color: #98f595;
    padding: 10px;
    font-size: 100%;
    width: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.801);
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-left: -19.5%;
    margin-top: 36%;
    position: absolute;
    z-index: 10000000;
  }

  .mintBtn {
    margin-left: -26%;
    margin-top: 33%;
    width: 20%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  .mintBtnHover {
    display: none;
    margin-left: -26%;
    margin-top: 33%;
    width: 20%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  .mintbuttondiv:hover .mintBtnHover {
    display: inline-block;
  }

  .btnfos-0-3:hover {
    background-color: #21af1b;
    color: white;
  }

  .btnfos-0-3:active {
    background-color: #10800c;
    transform: translateY(4px);
  }

  .btnfos-0-4 {
    background-color: #252525;
    color: #98f595;
    padding: 10px;
    font-size: 100%;
    width: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.801);
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 35%;
    margin-left: -19.8%;
    position: absolute;
    display: block;
  }

  .btnfos-0-4:hover {
    background-color: #21af1b;
    color: white;
  }

  .btnfos-0-4:active {
    background-color: #10800c;
    transform: translateY(4px);
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .formMain2 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(0, 0, 0);
    padding: 30px;
    border: #8afd8a 2px dashed;
    box-shadow: 0 0 20px #56cc087e;
    animation: popupAnimation 0.5s ease forwards;
    width: 90%;

  }

  .loading-bar {
    width: 100%;
    height: 20px;
    background-color: #f0f0f0;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
  }

  .loading-bar:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    background-color: #0fce0f;
    animation: loading 2s linear infinite;
  }


  .success-message {
    color: #50ec30;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 20px;
    margin-top: 0;
    margin-top: 25px;
  }

  .success-message p {
    font-size: 16px;
    margin-bottom: 0;
  }

  .pepe {
    text-align: center;
    color: #0fce0f;
    margin-top: 20px;
  }

  .errorMessage {
    margin-top: 6%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    z-index: 10000000;
    color: white;
  }

  .loadText {
    text-align: center;
    color: #0fce0f;
    font-size: 30px;
    z-index: 100000000000000000;

  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;

  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(40, 141, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(0, 139, 42),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(40, 141, 0),
        .5em 0 0 rgb(40, 141, 0);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Anton', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 16px;
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 2rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #fbe8f300;
    border: 2px rgb(160, 160, 160) solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .accordin {
    width: 80%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 16px;
    text-shadow: 0 0 10px #a2ec95;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-shadow: 0 0 10px #7cb100;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  /*Matrix*/
  #container {
    background-color: #007fc800;
    color: #0fce0f;
    width: 100%;
    height: 100vh;
    z-index: 1000000000000000000000000000000000000;

  }

  canvas {
    background-color: rgba(0, 0, 0, 0);
  }

  .conatinerC {
    z-index: 100000000000000000000;
  }

  .hackers {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
  }

  .hackersMobile {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    display: none;
    position: absolute;
  }

  .mintArea {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .code {
    width: 300px;
  }

  .errorCode {
    font-size: 30px;
    color: white;
    z-index: 10;
    text-align: center;
    margin-top: 21%;
    margin-left: 1%;
    text-transform: uppercase;
  }


  .glitch2-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    margin-top: 32%;
    margin-left: -66.6%;
    width: 100%;
  }

  .glitch2 {
    color: white;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    display: flex;
    justify-content: center;
    text-transform: upercase;
    position: absolute;
    display: inline-block;
    font-size: 0.5rem;
    color: white;
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
    line-height: 35px;

  }

  .glitch2::before,
  .glitch2::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch2::before {
    left: 2px;
    text-shadow: -2px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch2::after {
    left: -2px;
    text-shadow: -2px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  .pill {
    width: 10%;
    z-index: 1;
    margin-top: 32%;
    margin-left: -78.5%;
    position: absolute;
    cursor: pointer;
    animation: floating 3.5s ease-in-out infinite;
  }

  .pill-2 {
    width: 10%;
    z-index: 1;
    margin-top: 38%;
    margin-left: -78.5%;
    position: absolute;
    cursor: pointer;
    animation: floating2A 4s ease-in-out infinite;

  }

  .pillSpan:hover {
    transition: transform .2s ease-in-out;
  }

  .pill:hover {
    animation: none;
    /* Disable the floating animation on hover */
    transform: scale(1.3) rotate(5deg);
  }

  .pill-2:hover {
    animation: none;
    /* Disable the floating animation on hover */
    transform: scale(1.3) rotate(5deg);
  }

  @keyframes floating {
    0% {
      transform: translateY(0) rotate(-10deg);
    }

    50% {
      transform: translateY(-10px) rotate(-20deg);
    }

    100% {
      transform: translateY(0) rotate(-10deg);
    }
  }

  @keyframes floating2A {
    0% {
      transform: translateY(0) rotate(10deg);
    }

    50% {
      transform: translateY(-10px) rotate(20deg);
    }

    100% {
      transform: translateY(0) rotate(10deg);
    }
  }

  .musicOn {
    position: absolute;
    z-index: 1;
    width: 15%;
    margin-top: 39.5%;
    margin-left: 86%;
    cursor: pointer;
  }

  .musicOn2 {
    z-index: 1;
    width: 30%;
    margin-top: 98%;
    margin-left: 95%;
    cursor: pointer;
    position: relative;
    z-index: 1;
    display: none;
  }

  .audioMainNew {
    position: relative;
    margin-top: -5%;
  }

  .hl {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
  }

  .audioLogo {
    width: 9%;
    position: absolute;
    z-index: 1;
    margin-top: 44%;
    margin-left: -12%;
  }

  .audioLogo2 {
    width: 60px;
    height: 60px;
    z-index: 1;
    position: absolute;
    margin-top: 5px;
  }

  .playBtn {
    width: 13px;
    height: 13px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
  }

  .playBtn2 {
    width: 15px;
    height: 15px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .playBtn:hover {
    transform: scale(1.2);
  }

  .playBtn2:hover {
    transform: scale(1.2);
  }

  .audioMain {
    display: none;
  }

  .audioMain2-2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 7px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    width: 43%;
    position: absolute;
    bottom: 23%;
  }

  .audioMain2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 5px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    display: flex;
    width: 58%;
    margin-top: 45%;
    margin-left: -14%;
  }

  .audioMainAb {
    position: fixed;
    bottom: 20px;
    width: 95%;
    z-index: 5000;
    margin-left: 10px;

  }

  .audioMainAb2 {
    position: fixed;
    bottom: 20px;
    width: 95%;

  }

  .audioMain2New {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-flow: row nowrap;
    display: flex;
    margin-right: auto;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    padding-left: 20px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
  }

  .audioMain3 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
  }

  .songDetails {
    margin-top: auto;
    margin-bottom: auto;
  }

  .songName {
    color: rgb(255, 255, 255);
    font-size: 15px;
    width: 170px;
  }

  .artist {
    color: rgba(255, 255, 255, 0.726);
    font-size: 10px;
  }

  .music-bar {
    width: 50px;
    height: 50px;
    display: block;
    border-radius: 100%;
    background-color: ffffff;
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 30px;
    margin-right: 5px;
  }

  .bar {
    display: block;
    background-color: #00b4ff;
    height: 20px;
    width: 2px;
    text-indent: -9999px;
    position: absolute;
    top: 13px;
    -webkit-animation: bar 0ms -1000ms linear infinite alternate running;
    animation: bar 0ms -1000ms linear infinite alternate running;
  }

  .bar2 {
    display: block;
    background-color: #00b4ff;
    height: 5px;
    width: 3px;
    text-indent: -9999px;
    position: absolute;
    top: 36.3px;
  }

  @-webkit-keyframes bar {
    0% {
      transform: scale(1, 0.25);
    }

    25% {
      transform: scale(1, 0.5);
    }

    50% {
      transform: scale(1, 0.75);
    }

    75% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1.25);
    }
  }

  @keyframes bar {
    0% {
      transform: scale(1, 0.25);
    }

    25% {
      transform: scale(1, 0.5);
    }

    50% {
      transform: scale(1, 0.75);
    }

    75% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1.25);
    }
  }

  .bar-0 {
    left: 7px;
    -webkit-animation-duration: 337ms;
    animation-duration: 337ms;
  }

  .bar-1 {
    left: 16px;
    -webkit-animation-duration: 321ms;
    animation-duration: 321ms;
  }

  .bar-2 {
    left: 25px;
    -webkit-animation-duration: 353ms;
    animation-duration: 353ms;
  }

  .bar-3 {
    left: 34px;
    -webkit-animation-duration: 341ms;
    animation-duration: 341ms;
  }

  .bar-4 {
    left: 42px;
    -webkit-animation-duration: 327ms;
    animation-duration: 327ms;
  }

  .wlLogo {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .wl1 {
    font-size: 25px;
    color: white;
    text-align: center;
  }

  .wl2 {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.884);
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .wlBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    color: white;
    background-color: #66eb1957;
    padding: 10px;
    width: 250px;
    border-image: linear-gradient(to right, #b0ff83, #80c73d);
    border-image-slice: 1;
    color: white;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    text-shadow: 0 0 10px #FFFFFF;
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 0 10px #9df8617e;

  }

  .wlBtn2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    color: white;
    background-color: #66eb1957;
    padding: 10px;
    width: 100%;
    border-image: linear-gradient(to right, #b0ff83, #80c73d);
    border-image-slice: 1;
    color: white;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    text-shadow: 0 0 10px #FFFFFF;
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 0 10px #9df8617e;

  }

  .wlBtn:hover {
    border-image: linear-gradient(to right, #e0f8d2, #acce8c);
    box-shadow: 0 0 10px #d4ffb77e;
    border-image-slice: 1;
    font-size: 20px;
  }

  .wlBtn2:hover {
    border-image: linear-gradient(to right, #e0f8d2, #acce8c);
    box-shadow: 0 0 10px #d4ffb77e;
    border-image-slice: 1;
    font-size: 20px;
  }

  .wlM2 {
    margin-top: 15vh;
    padding-bottom: 5vh;
  }

  .wlCard {
    position: absolute;
    width: 25%;
    z-index: 1;
    margin-left: 65%;
    margin-top: 7%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.5s;
    cursor: pointer;
    filter: grayscale(1);
  }

  .wlCard:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
    transform: scale(1.1);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    filter: grayscale(0);

  }

  .wlCard:active {
    margin-top: 20px;
  }

  .pillWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: floatingUpAndDown 6.5s ease-in-out infinite;
    width: 60%;
  }

  @keyframes floatingUpAndDown {
    0% {
      transform: translateY(0) rotate(-10deg);
    }

    50% {
      transform: translateY(-20px) rotate(-12.5deg);
    }

    100% {
      transform: translateY(0) rotate(-10deg);
    }
  }

  .stickyPic {
    position: sticky;
    bottom: 23%;
    margin-left: 80%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
  }

  .wlInfo {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: auto;
  }

  .formCon div {
    color: white;
    margin-bottom: 10px;
  }

  .formCon {
    margin-bottom: 30px;
    color: white;
  }

  input {
    width: 300px;
    padding: 7px;
    border: none;
    background-color: #cccccc00;
    color: #c6f3c6;
    border: 1px solid white;
  }


  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 55%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(0, 0, 0);
    padding: 20px;
    border: #8afd8a 2px dashed;
    box-shadow: 0 0 20px #56cc087e;
    animation: popupAnimation 0.5s ease forwards;
  }

  @keyframes popupAnimation {
    0% {
      opacity: 0;
      transform: translate(-50%, -60%);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 5%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 20px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
    transition: transform .2s ease-in-out;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(178, 236, 139);
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  
  .glitchVaultH {
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-size: 40px;
    color: white;
    text-align: center;
    margin-top: 30vh;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.425);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 100%;
   }
  
  .glitchVaultH::before,
  .glitchVaultH::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .glitchVaultH::before {
    left: 2px;
    text-shadow: -2px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }
  
  .glitchVaultH::after {
    left: -2px;
    text-shadow: -2px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }
  
  .loadingText-2 {
    text-align: center;
    color: white;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .success-2 {
    text-align: center;
    color: #50ec30;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .error-2 {
    text-align: center;
    color: #fc4741;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .pepeImg {
    width: 120px;
    height: 47px;
    margin-right: 10px;
    margin-left: 5px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #43c923, #287914);
    border-image-slice: 1;
    box-shadow: 0 0 10px #56cc087e;

  }

  .miniBarMain {
    padding-top: 30px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-between;
    z-index: 1;

  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 6px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;

  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #c5f8b8 1px solid;
    z-index: 0;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;

  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }

  .bluePillDiv {
    width: 100%;
    height: 100vh;
    background: #272727;
    z-index: 1000000000000000;
    position: fixed;
  }

  .loading2 {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    z-index: 1000000000000000000000000000000000000;
    position: fixed;

  }

  .page-loader .txt {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 50%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    font-size: 20px;
    margin-left: 38%;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
  }

  .page-loaderNew .txt {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 50%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    font-size: 20px;
    margin-left: 38%;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
  }

  .back {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 90%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    margin-left: 1%;
    width: 50px;
    height: 50px;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .back:hover {
    transform: scale(1.2);
  }

  .spinner {
    position: relative;
    top: 35%;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #0000009a;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #00000000;
    z-index: 1000000;
    display: none;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #000000;
    z-index: 1000000000000;
    display: none;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers3 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #00000000;
    display: none;
    z-index: 9000000000000;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    width: 90%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .rightImg {
    width: 28px;
    height: 28px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    cursor: pointer;
    z-index: 5000000000;
  }



  .left {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #3eec12;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    z-index: 0;

  }

  #fontSize:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 100000000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 16px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #43c923, #287914);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    box-shadow: 0 0 10px #56cc087e;
    z-index: 1000000000;

  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #f0f7da;
  }

  .connect2 {
    z-index: 100000000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .allWrap2 {
    background: url('./assets/anon.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .wlMain {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.07326680672268904) 0%, rgba(0, 0, 0, 0.2721463585434174) 49%, rgba(0, 0, 0, 0.8127626050420168) 100%), url('./assets/dataserver.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  #container2 {
    background-color: #007fc800;
    color: #0fce0f;
    width: 100%;
    opacity: 0.35;
    position: absolute;
    height: 550vh;
  }

  .light2 {
    height: 550vh;

  }

  .cont {

    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    background-size: cover;
  }

  .cont2 {
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;

      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;

      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }
  }

  .introduction {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .logo {
    width: 70px;
    height: 70px;
    border-radius: 100px;
    z-index: 5;
  }

  .logoDiv {
    display: flex;
    justify-content: center;

  }

  .title {
    font-size: 30px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
    font-family: 'Matrix', sans-serif;
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 25px;
    color: white;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    letter-spacing: 10px;
    font-family: 'Matrix', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    margin-top: 7%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://img.freepik.com/free-vector/stage-illuminated-by-spotlights_107791-928.jpg?w=740&t=st=1683982217~exp=1683982817~hmac=0f871c85d3f1a98262cfca6dc3abcff569a607560ea2e5da5691c0e9f2fcefd5') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Footer {
    margin-top: 1500px;
    padding-bottom: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transition: 0.5s;
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }


  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 23px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    color: #ffffff;
    text-align: center;
    text-shadow: 0 0 10px #68ff4e;
  }

  .conT2 {
    font-size: 75px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .storyCon {
    font-size: 15px;
    width: 90%;
    color: white;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;

  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    z-index: 1;

  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 28%;
    z-index: 10000000;
    font-weight: 500;
    position: absolute;
    margin-left: -20%;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 50000000000;
    position: absolute;
    margin-top: 28%;
    margin-left: -25%;
    width: 12%;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10;
    margin-top: 2%;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    margin-left: 2%;
    margin-right: 2%;
    border: none;
    padding: 5px;
    z-index: 900000000;
    cursor: pointer;
    background-color: #3a9ae800;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
    display: block;
    width: 40%;
    position: absolute;
  }

  .nftamount {
    color: #ffffff;
    font-size: 1.6rem;
    z-index: 100000000000000000;
    text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #49ff18, 0 0 30px #49FF18, 0 0 40px #49FF18, 0 0 55px #49FF18, 0 0 75px #49ff18;
    position: absolute;
    margin-left: 9%;
    margin-top: -10%;
  }


  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #24d600 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #252525;
    color: #98f595;
    padding: 10px;
    font-size: 100%;
    width: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.801);
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-left: -19.5%;
    margin-top: 36%;
    position: absolute;
    z-index: 10000000;
  }

  .mintBtn {
    margin-left: -26%;
    margin-top: 33%;
    width: 20%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  .mintBtnHover {
    display: none;
    margin-left: -26%;
    margin-top: 33%;
    width: 20%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  .mintbuttondiv:hover .mintBtnHover {
    display: inline-block;
  }

  .btnfos-0-3:hover {
    background-color: #21af1b;
    color: white;
  }

  .btnfos-0-3:active {
    background-color: #10800c;
    transform: translateY(4px);
  }

  .btnfos-0-4 {
    background-color: #252525;
    color: #98f595;
    padding: 10px;
    font-size: 100%;
    width: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.801);
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 35%;
    margin-left: -19.8%;
    position: absolute;
    display: block;
  }

  .btnfos-0-4:hover {
    background-color: #21af1b;
    color: white;
  }

  .btnfos-0-4:active {
    background-color: #10800c;
    transform: translateY(4px);
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }


  .formMain2 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(0, 0, 0);
    padding: 20px;
    border: #8afd8a 2px dashed;
    box-shadow: 0 0 20px #56cc087e;
    animation: popupAnimation 0.5s ease forwards;
    width: fit-content;
  }

  .loading-bar {
    width: 300px;
    height: 20px;
    background-color: #f0f0f0;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
  }

  .loading-bar:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    background-color: #0fce0f;
    animation: loading 2s linear infinite;
  }


  .success-message {
    color: #50ec30;
    border-radius: 5px;
    text-align: center;
    margin-left: auto;
    margin-left: auto;
    justify-content: center;
    margin-top: 30px;
  }

  .success-message h2 {
    font-size: 20px;
    margin-top: 0;
    margin-left: auto;
    margin-left: auto;
    justify-content: center;
  }

  .success-message p {
    font-size: 16px;
    margin-bottom: 0;
    margin-left: auto;
    margin-left: auto;
    justify-content: center;
  }

  .pepe {
    text-align: center;
    color: #0fce0f;
    margin-top: 20px;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    z-index: 10000000;
    color: white;
  }

  .loadText {
    text-align: center;
    color: #0fce0f;
    font-size: 30px;
    z-index: 100000000000000000;

  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(40, 141, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(0, 139, 42),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(40, 141, 0),
        .5em 0 0 rgb(40, 141, 0);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Anton', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 16px;
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 2rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #fbe8f300;
    border: 2px rgb(160, 160, 160) solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .accordin {
    width: 80%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 16px;
    text-shadow: 0 0 10px #a2ec95;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-shadow: 0 0 10px #7cb100;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  /*Matrix*/
  #container {
    background-color: #007fc800;
    color: #0fce0f;
    width: 100%;
    height: 100vh;
    z-index: 1000000000000000000000000000000000000;

  }

  canvas {
    background-color: rgba(0, 0, 0, 0);
  }

  .conatinerC {
    z-index: 100000000000000000000;
  }

  .hackers {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
  }

  .hackersMobile {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    display: none;
  }

  .mintArea {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .code {
    width: 300px;
  }

  .errorCode {
    font-size: 30px;
    color: white;
    z-index: 10;
    text-align: center;
    margin-top: 21%;
    margin-left: 1%;
    text-transform: uppercase;
  }


  .glitch2-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    margin-top: 32%;
    margin-left: -66.4%;
    width: 100%;
  }

  .glitch2 {
    color: white;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    display: flex;
    justify-content: center;
    text-transform: upercase;
    position: absolute;
    display: inline-block;
    font-size: 0.7rem;
    color: white;
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
    line-height: 35px;

  }

  .glitch2::before,
  .glitch2::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch2::before {
    left: 2px;
    text-shadow: -2px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch2::after {
    left: -2px;
    text-shadow: -2px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  .pill {
    width: 10%;
    z-index: 1;
    margin-top: 32%;
    margin-left: -78.5%;
    position: absolute;
    cursor: pointer;
    animation: floating 3.5s ease-in-out infinite;
  }

  .pill-2 {
    width: 10%;
    z-index: 1;
    margin-top: 38%;
    margin-left: -78.5%;
    position: absolute;
    cursor: pointer;
    animation: floating2A 4s ease-in-out infinite;

  }

  .pillSpan:hover {
    transition: transform .2s ease-in-out;
  }

  .pill:hover {
    animation: none;
    /* Disable the floating animation on hover */
    transform: scale(1.3) rotate(5deg);
  }

  .pill-2:hover {
    animation: none;
    /* Disable the floating animation on hover */
    transform: scale(1.3) rotate(5deg);
  }

  @keyframes floating {
    0% {
      transform: translateY(0) rotate(-10deg);
    }

    50% {
      transform: translateY(-10px) rotate(-20deg);
    }

    100% {
      transform: translateY(0) rotate(-10deg);
    }
  }

  @keyframes floating2A {
    0% {
      transform: translateY(0) rotate(10deg);
    }

    50% {
      transform: translateY(-10px) rotate(20deg);
    }

    100% {
      transform: translateY(0) rotate(10deg);
    }
  }

  .musicOn {
    position: absolute;
    z-index: 1;
    width: 15%;
    margin-top: 39.5%;
    margin-left: 86%;
    cursor: pointer;
  }

  .musicOn2 {
    z-index: 1;
    width: 30%;
    margin-top: 98%;
    margin-left: 95%;
    cursor: pointer;
    position: relative;
    z-index: 1;
    display: none;
  }

  .audioMainNew {
    position: relative;
    margin-top: -5%;
  }

  .hl {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
  }

  .audioLogo {
    width: 9%;
    position: absolute;
    z-index: 1;
    margin-top: 44%;
    margin-left: -12%;
  }

  .audioLogo2 {
    width: 70px;
    height: 70px;
    z-index: 1;
    position: absolute;
  }

  .playBtn {
    width: 16px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 20px;
  }

  .playBtn2 {
    width: 15px;
    height: 15px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .playBtn:hover {
    transform: scale(1.2);
  }

  .playBtn2:hover {
    transform: scale(1.2);
  }

  .audioMain {
    display: none;
  }

  .audioMain2-2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 7px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    width: 43%;
    position: absolute;
    bottom: 23%;
  }

  .audioMain2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 5px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    display: flex;
    width: 58%;
    margin-top: 45%;
    margin-left: -14%;
  }

  .audioMainAb {
    position: fixed;
    bottom: 20px;
    z-index: 5000;
    margin-left: 10px;
  }

  .audioMainAb2 {
    position: fixed;
    bottom: 20px;
  }

  .audioMain2New {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-flow: row nowrap;
    display: flex;
    margin-right: auto;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
  }

  .audioMain3 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
  }

  .songDetails {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
    margin-right: 20px;
  }

  .songName {
    color: rgb(255, 255, 255);
    font-size: 15px;
    width: 170px;
  }

  .artist {
    color: rgba(255, 255, 255, 0.726);
    font-size: 10px;
  }

  .music-bar {
    width: 55px;
    height: 55px;
    display: block;
    border-radius: 100%;
    background-color: ffffff;
    position: relative;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .bar {
    display: block;
    background-color: #00b4ff;
    height: 25px;
    width: 3px;
    text-indent: -9999px;
    position: absolute;
    top: 13px;
    -webkit-animation: bar 0ms -1000ms linear infinite alternate running;
    animation: bar 0ms -1000ms linear infinite alternate running;
  }

  .bar2 {
    display: block;
    background-color: #00b4ff;
    height: 5px;
    width: 3px;
    text-indent: -9999px;
    position: absolute;
    top: 36.3px;
  }

  @-webkit-keyframes bar {
    0% {
      transform: scale(1, 0.25);
    }

    25% {
      transform: scale(1, 0.5);
    }

    50% {
      transform: scale(1, 0.75);
    }

    75% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1.25);
    }
  }

  @keyframes bar {
    0% {
      transform: scale(1, 0.25);
    }

    25% {
      transform: scale(1, 0.5);
    }

    50% {
      transform: scale(1, 0.75);
    }

    75% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1.25);
    }
  }

  .bar-0 {
    left: 7px;
    -webkit-animation-duration: 337ms;
    animation-duration: 337ms;
  }

  .bar-1 {
    left: 16px;
    -webkit-animation-duration: 321ms;
    animation-duration: 321ms;
  }

  .bar-2 {
    left: 25px;
    -webkit-animation-duration: 353ms;
    animation-duration: 353ms;
  }

  .bar-3 {
    left: 34px;
    -webkit-animation-duration: 341ms;
    animation-duration: 341ms;
  }

  .bar-4 {
    left: 42px;
    -webkit-animation-duration: 327ms;
    animation-duration: 327ms;
  }

  .wlLogo {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .wl1 {
    font-size: 30px;
    color: white;
    text-align: center;
  }

  .wl2 {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.884);
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .wlBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    color: white;
    background-color: #66eb1957;
    padding: 10px;
    width: 300px;
    border-image: linear-gradient(to right, #b0ff83, #80c73d);
    border-image-slice: 1;
    color: white;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    text-shadow: 0 0 10px #FFFFFF;
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 0 10px #9df8617e;

  }

  .wlBtn2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    color: white;
    background-color: #66eb1957;
    padding: 10px;
    width: 100%;
    border-image: linear-gradient(to right, #b0ff83, #80c73d);
    border-image-slice: 1;
    color: white;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    text-shadow: 0 0 10px #FFFFFF;
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 0 10px #9df8617e;

  }

  .wlBtn:hover {
    border-image: linear-gradient(to right, #e0f8d2, #acce8c);
    box-shadow: 0 0 10px #d4ffb77e;
    border-image-slice: 1;
    font-size: 20px;
  }

  .wlBtn2:hover {
    border-image: linear-gradient(to right, #e0f8d2, #acce8c);
    box-shadow: 0 0 10px #d4ffb77e;
    border-image-slice: 1;
    font-size: 20px;
  }

  .wlM2 {
    margin-top: 15vh;
    padding-bottom: 5vh;
  }

  .wlCard {
    position: absolute;
    width: 25%;
    z-index: 1;
    margin-left: 65%;
    margin-top: 7%;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.5s;
    cursor: pointer;
    filter: grayscale(1);

  }

  .wlCard:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
    transform: scale(1.1);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    filter: grayscale(0);

  }

  .wlCard:active {
    margin-top: 20px;
  }

  .pillWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: floatingUpAndDown 6.5s ease-in-out infinite;
    width: 60%;
  }

  @keyframes floatingUpAndDown {
    0% {
      transform: translateY(0) rotate(-10deg);
    }

    50% {
      transform: translateY(-20px) rotate(-12.5deg);
    }

    100% {
      transform: translateY(0) rotate(-10deg);
    }
  }

  .stickyPic {
    position: sticky;
    bottom: 23%;
    margin-left: 80%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
  }

  .wlInfo {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: auto;
  }

  .formCon div {
    color: white;
    margin-bottom: 10px;
  }

  .formCon {
    margin-bottom: 30px;
    color: white;
  }

  input {
    width: 300px;
    padding: 7px;
    border: none;
    background-color: #cccccc00;
    color: #c6f3c6;
    border: 1px solid white;
  }


  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 60%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(0, 0, 0);
    padding: 50px;
    border: #8afd8a 2px dashed;
    box-shadow: 0 0 20px #56cc087e;
    animation: popupAnimation 0.5s ease forwards;
  }

  @keyframes popupAnimation {
    0% {
      opacity: 0;
      transform: translate(-50%, -60%);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 5%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 20px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
    transition: transform .2s ease-in-out;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(178, 236, 139);
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  
  .glitchVaultH {
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-size: 50px;
    color: white;
    text-align: center;
    margin-top: 28vh;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.425);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 100%;
   }
  
  .glitchVaultH::before,
  .glitchVaultH::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .glitchVaultH::before {
    left: 2px;
    text-shadow: -2px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }
  
  .glitchVaultH::after {
    left: -2px;
    text-shadow: -2px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }
  
  .loadingText-2 {
    text-align: center;
    color: white;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .success-2 {
    text-align: center;
    color: #50ec30;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .error-2 {
    text-align: center;
    color: #fc4741;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .pepeImg {
    width: 120px;
    height: 47px;
    margin-right: 10px;
    margin-left: 5px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #43c923, #287914);
    border-image-slice: 1;
    box-shadow: 0 0 10px #56cc087e;

  }

  .miniBarMain {
    padding-top: 30px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-between;
    z-index: 1;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 15px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #c5f8b8 1px solid;
    z-index: 0;

  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }

  .bluePillDiv {
    width: 100%;
    height: 100vh;
    background: #272727;
    z-index: 1000000000000000;
    position: fixed;
  }

  .loading2 {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    z-index: 1000000000000000000000000000000000000;
    position: fixed;

  }

  .page-loader .txt {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 50%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    font-size: 20px;
    margin-left: 42%;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
  }

  .page-loaderNew .txt {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 50%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    font-size: 20px;
    margin-left: 45%;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
  }


  .back {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 90%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    margin-left: 1%;
    width: 50px;
    height: 50px;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .back:hover {
    transform: scale(1.2);
  }

  .spinner {
    position: relative;
    top: 35%;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #0000009a;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #00000000;
    z-index: 1000000;
    display: none;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #000000;
    z-index: 1000000;
    display: none;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers3 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #00000000;
    display: none;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    width: 90%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .rightImg {
    width: 28px;
    height: 28px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    cursor: pointer;
    z-index: 1;
  }

  .left {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #3eec12;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    z-index: 0;

  }

  #fontSize:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 16px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #43c923, #287914);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    box-shadow: 0 0 10px #56cc087e;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #f0f7da;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .allWrap2 {
    background: url('./assets/anon.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .wlMain {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.07326680672268904) 0%, rgba(0, 0, 0, 0.2721463585434174) 49%, rgba(0, 0, 0, 0.8127626050420168) 100%), url('./assets/dataserver.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  #container2 {
    background-color: #007fc800;
    color: #0fce0f;
    width: 100%;
    opacity: 0.35;
    position: absolute;
    height: 530vh;
  }

  .light2 {
    height: 530vh;

  }

  .cont {

    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    background-size: cover;
  }

  .cont2 {
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;

      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;

      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }
  }

  .introduction {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .logo {
    width: 80px;
    height: 80px;
    border-radius: 100px;
    z-index: 5;
  }

  .logoDiv {
    display: flex;
    justify-content: center;

  }

  .title {
    font-size: 30px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
    font-family: 'Matrix', sans-serif;
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 35px;
    color: white;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 30px;
    display: flex;
    justify-content: center;
    letter-spacing: 15px;
    font-family: 'Matrix', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    margin-top: 7%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://img.freepik.com/free-vector/stage-illuminated-by-spotlights_107791-928.jpg?w=740&t=st=1683982217~exp=1683982817~hmac=0f871c85d3f1a98262cfca6dc3abcff569a607560ea2e5da5691c0e9f2fcefd5') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Footer {
    margin-top: 1500px;
    padding-bottom: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transition: 0.5s;
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }


  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 25px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    color: #ffffff;
    text-align: center;
    text-shadow: 0 0 10px #68ff4e;
  }

  .conT2 {
    font-size: 75px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .storyCon {
    font-size: 16px;
    width: 80%;
    color: white;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;

  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    z-index: 1;

  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 28%;
    z-index: 10000000;
    font-weight: 500;
    position: absolute;
    margin-left: -20%;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 50000000000;
    position: absolute;
    margin-top: 30%;
    margin-left: -23%;
    width: 10%;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10;
    margin-top: 2%;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    margin-left: 2%;
    margin-right: 2%;
    border: none;
    padding: 5px;
    z-index: 900000000;
    cursor: pointer;
    background-color: #3a9ae800;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
    display: block;
    width: 35%;
    position: absolute;
  }

  .nftamount {
    color: #ffffff;
    font-size: 1.8rem;
    z-index: 100000000000000000;
    text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #49ff18, 0 0 30px #49FF18, 0 0 40px #49FF18, 0 0 55px #49FF18, 0 0 75px #49ff18;
    position: absolute;
    margin-left: 7%;
    margin-top: -10%;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #24d600 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #252525;
    color: #98f595;
    padding: 10px;
    font-size: 100%;
    width: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.801);
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-left: -19.5%;
    margin-top: 36%;
    position: absolute;
    z-index: 10000000;
  }

  .mintBtn {
    margin-left: -24%;
    margin-top: 34%;
    width: 16%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  .mintBtnHover {
    display: none;
    margin-left: -24%;
    margin-top: 34%;
    width: 16%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  .mintbuttondiv:hover .mintBtnHover {
    display: inline-block;
  }

  .btnfos-0-3:hover {
    background-color: #21af1b;
    color: white;
  }

  .btnfos-0-3:active {
    background-color: #10800c;
    transform: translateY(4px);
  }

  .btnfos-0-4 {
    background-color: #252525;
    color: #98f595;
    padding: 10px;
    font-size: 100%;
    width: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.801);
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 35%;
    margin-left: -19.8%;
    position: absolute;
    display: block;
  }

  .btnfos-0-4:hover {
    background-color: #21af1b;
    color: white;
  }

  .btnfos-0-4:active {
    background-color: #10800c;
    transform: translateY(4px);
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .formMain2 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(0, 0, 0);
    padding: 50px;
    border: #8afd8a 2px dashed;
    box-shadow: 0 0 20px #56cc087e;
    animation: popupAnimation 0.5s ease forwards;
  }

  .loading-bar {
    width: 500px;
    height: 20px;
    background-color: #f0f0f0;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
  }

  .loading-bar:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    background-color: #0fce0f;
    animation: loading 2s linear infinite;
  }


  .success-message {
    color: #50ec30;
    border-radius: 5px;
    text-align: center;
    margin-left: auto;
    margin-left: auto;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .pepe {
    text-align: center;
    color: #0fce0f;
    margin-top: 20px;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    z-index: 10000000;
    color: white;
  }

  .loadText {
    text-align: center;
    color: #0fce0f;
    font-size: 30px;
    z-index: 100000000000000000;

  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;

  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(40, 141, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(0, 139, 42),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(40, 141, 0),
        .5em 0 0 rgb(40, 141, 0);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Anton', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 18px;
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 2rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #fbe8f300;
    border: 2px rgb(160, 160, 160) solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .accordin {
    width: 75%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    text-shadow: 0 0 10px #a2ec95;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-shadow: 0 0 10px #7cb100;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  /*Matrix*/
  #container {
    background-color: #007fc800;
    color: #0fce0f;
    width: 100%;
    height: 100vh;
    z-index: 1000000000000000000000000000000000000;

  }

  canvas {
    background-color: rgba(0, 0, 0, 0);
  }

  .conatinerC {
    z-index: 100000000000000000000;
  }

  .hackers {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
  }

  .hackersMobile {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    display: none;
  }

  .mintArea {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .code {
    width: 300px;
  }

  .errorCode {
    font-size: 30px;
    color: white;
    z-index: 10;
    text-align: center;
    margin-top: 21%;
    margin-left: 1%;
    text-transform: uppercase;
  }


  .glitch2-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    margin-top: 32%;
    margin-left: -66.4%;
    width: 100%;
  }

  .glitch2 {
    color: white;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    display: flex;
    justify-content: center;
    text-transform: upercase;
    position: absolute;
    display: inline-block;
    font-size: 1rem;
    color: white;
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
    line-height: 35px;

  }

  .glitch2::before,
  .glitch2::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch2::before {
    left: 2px;
    text-shadow: -2px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch2::after {
    left: -2px;
    text-shadow: -2px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  .pill {
    width: 10%;
    z-index: 10000000;
    margin-top: 32%;
    margin-left: -78.5%;
    position: absolute;
    cursor: pointer;
    animation: floating 3.5s ease-in-out infinite;
  }

  .pill-2 {
    width: 10%;
    z-index: 10000000;
    margin-top: 38%;
    margin-left: -78.5%;
    position: absolute;
    cursor: pointer;
    animation: floating2A 4s ease-in-out infinite;

  }

  .pillSpan:hover {
    transition: transform .2s ease-in-out;
  }

  .pill:hover {
    animation: none;
    /* Disable the floating animation on hover */
    transform: scale(1.3) rotate(5deg);
  }

  .pill-2:hover {
    animation: none;
    /* Disable the floating animation on hover */
    transform: scale(1.3) rotate(5deg);
  }

  @keyframes floating {
    0% {
      transform: translateY(0) rotate(-10deg);
    }

    50% {
      transform: translateY(-10px) rotate(-20deg);
    }

    100% {
      transform: translateY(0) rotate(-10deg);
    }
  }

  @keyframes floating2A {
    0% {
      transform: translateY(0) rotate(10deg);
    }

    50% {
      transform: translateY(-10px) rotate(20deg);
    }

    100% {
      transform: translateY(0) rotate(10deg);
    }
  }

  .musicOn {
    position: absolute;
    z-index: 1;
    width: 15%;
    margin-top: 39.5%;
    margin-left: 86%;
    cursor: pointer;
  }

  .musicOn2 {
    z-index: 1;
    width: 30%;
    margin-top: 98%;
    margin-left: 95%;
    cursor: pointer;
    position: relative;
    z-index: 1;
    display: none;
  }

  .audioMainNew {
    position: relative;
    margin-top: -5%;
  }

  .hl {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
  }

  .audioLogo {
    width: 9%;
    position: absolute;
    z-index: 1;
    margin-top: 44%;
    margin-left: -12%;
  }

  .audioLogo2 {
    width: 95px;
    height: 95px;
    z-index: 1;
    position: absolute;
    margin-left: 3%;
    margin-top: -3%;
  }

  .playBtn {
    width: 18px;
    height: 18px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    margin-left: 25px;
    margin-right: 25px;
  }

  .playBtn2 {
    width: 16px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .playBtn:hover {
    transform: scale(1.2);
  }

  .playBtn2:hover {
    transform: scale(1.2);
  }

  .audioMain {
    display: none;
  }

  .audioMain2-2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 7px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    width: 43%;
    position: absolute;
    bottom: 23%;
  }

  .audioMain2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 5px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    display: flex;
    width: 58%;
    margin-top: 45%;
    margin-left: -14%;
  }

  .audioMainAb2 {
    position: fixed;
    margin-left: 20px;
    bottom: 20px;
    z-index: 5000;

  }

  .audioMainAb {
    position: fixed;
    margin-left: 20%;
    bottom: 30px;
    z-index: 50;
  }

  .audioMain2New {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-flow: row nowrap;
    display: flex;
    margin-right: auto;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    width: fit-content;
    padding-right: 10%;
    padding-left: 20%;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
  }


  .audioMain3 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
  }

  .songDetails {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
    margin-right: 20px;
  }

  .songName {
    color: rgb(255, 255, 255);
    font-size: 17px;
    width: 200px;
  }

  .artist {
    color: rgba(255, 255, 255, 0.726);
    font-size: 11px;
  }

  .music-bar {
    width: 55px;
    height: 55px;
    display: block;
    border-radius: 100%;
    background-color: ffffff;
    position: relative;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .bar {
    display: block;
    background-color: #00b4ff;
    height: 25px;
    width: 3px;
    text-indent: -9999px;
    position: absolute;
    top: 13px;
    -webkit-animation: bar 0ms -1000ms linear infinite alternate running;
    animation: bar 0ms -1000ms linear infinite alternate running;
  }

  .bar2 {
    display: block;
    background-color: #00b4ff;
    height: 5px;
    width: 3px;
    text-indent: -9999px;
    position: absolute;
    top: 36.3px;
  }

  @-webkit-keyframes bar {
    0% {
      transform: scale(1, 0.25);
    }

    25% {
      transform: scale(1, 0.5);
    }

    50% {
      transform: scale(1, 0.75);
    }

    75% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1.25);
    }
  }

  @keyframes bar {
    0% {
      transform: scale(1, 0.25);
    }

    25% {
      transform: scale(1, 0.5);
    }

    50% {
      transform: scale(1, 0.75);
    }

    75% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1.25);
    }
  }

  .bar-0 {
    left: 7px;
    -webkit-animation-duration: 337ms;
    animation-duration: 337ms;
  }

  .bar-1 {
    left: 16px;
    -webkit-animation-duration: 321ms;
    animation-duration: 321ms;
  }

  .bar-2 {
    left: 25px;
    -webkit-animation-duration: 353ms;
    animation-duration: 353ms;
  }

  .bar-3 {
    left: 34px;
    -webkit-animation-duration: 341ms;
    animation-duration: 341ms;
  }

  .bar-4 {
    left: 42px;
    -webkit-animation-duration: 327ms;
    animation-duration: 327ms;
  }

  .wlLogo {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .wl1 {
    font-size: 30px;
    color: white;
    text-align: center;
  }

  .wl2 {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.884);
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  .wlBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    color: white;
    background-color: #66eb1957;
    padding: 10px;
    width: 300px;
    border-image: linear-gradient(to right, #b0ff83, #80c73d);
    border-image-slice: 1;
    color: white;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    text-shadow: 0 0 10px #FFFFFF;
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 0 10px #9df8617e;

  }

  .wlBtn2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    color: white;
    background-color: #66eb1957;
    padding: 10px;
    width: 100%;
    border-image: linear-gradient(to right, #b0ff83, #80c73d);
    border-image-slice: 1;
    color: white;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    text-shadow: 0 0 10px #FFFFFF;
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 0 10px #9df8617e;

  }

  .wlBtn:hover {
    border-image: linear-gradient(to right, #e0f8d2, #acce8c);
    box-shadow: 0 0 10px #d4ffb77e;
    border-image-slice: 1;
    font-size: 20px;
  }

  .wlBtn2:hover {
    border-image: linear-gradient(to right, #e0f8d2, #acce8c);
    box-shadow: 0 0 10px #d4ffb77e;
    border-image-slice: 1;
    font-size: 20px;
  }

  .wlM2 {
    margin-top: 15vh;
    padding-bottom: 5vh;
  }

  .wlCard {
    position: absolute;
    width: 22%;
    z-index: 1;
    margin-left: 70%;
    margin-top: 7%;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.5s;
    transform-origin: center;
    transform: perspective(800px) rotateY(-20deg);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    filter: grayscale(1);

  }

  .wlCard:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
    transform: scale(1.1);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    filter: grayscale(0);

  }

  .wlCard:active {
    margin-top: 20px;
  }

  .pillWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: floatingUpAndDown 6.5s ease-in-out infinite;
    width: 40%;
  }

  @keyframes floatingUpAndDown {
    0% {
      transform: translateY(0) rotate(-10deg);
    }

    50% {
      transform: translateY(-20px) rotate(-12.5deg);
    }

    100% {
      transform: translateY(0) rotate(-10deg);
    }
  }

  .stickyPic {
    position: sticky;
    bottom: 23%;
    margin-left: 80%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
  }

  .wlInfo {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: auto;
  }

  .formCon div {
    color: white;
    margin-bottom: 10px;
  }

  .formCon {
    margin-bottom: 30px;
    color: white;
  }

  input {
    width: 300px;
    padding: 7px;
    border: none;
    background-color: #cccccc00;
    color: #c6f3c6;
    border: 1px solid white;
  }


  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 60%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(0, 0, 0);
    padding: 50px;
    border: #8afd8a 2px dashed;
    box-shadow: 0 0 20px #56cc087e;
    animation: popupAnimation 0.5s ease forwards;
  }

  @keyframes popupAnimation {
    0% {
      opacity: 0;
      transform: translate(-50%, -60%);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 5%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 20px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
    transition: transform .2s ease-in-out;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(178, 236, 139);
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .glitchVaultH {
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-size: 50px;
    color: white;
    text-align: center;
    margin-top: 38vh;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.425);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 100%;
   }
  
  .glitchVaultH::before,
  .glitchVaultH::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .glitchVaultH::before {
    left: 2px;
    text-shadow: -2px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }
  
  .glitchVaultH::after {
    left: -2px;
    text-shadow: -2px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }
  .loadingText-2 {
    text-align: center;
    color: white;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .success-2 {
    text-align: center;
    color: #50ec30;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .error-2 {
    text-align: center;
    color: #fc4741;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .pepeImg {
    width: 110px;
    height: 43px;
    margin-right: 10px;
    margin-left: 5px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #43c923, #287914);
    border-image-slice: 1;
    box-shadow: 0 0 10px #56cc087e;

  }

  .audioMainAb2 {
    display: none;
  }

  .bluePillDiv {
    width: 100%;
    height: 100vh;
    background: #272727;
    z-index: 1000000000000000;
    position: fixed;
  }

  .loading2 {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    z-index: 1000000000000000000000000000000000000;
    position: fixed;

  }

  .page-loader .txt {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 50%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    font-size: 20px;
    margin-left: 43%;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
  }

  .page-loaderNew .txt {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 50%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    font-size: 20px;
    margin-left: 45%;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
  }


  .back {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 90%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    margin-left: 1%;
    width: 50px;
    height: 50px;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .back:hover {
    transform: scale(1.2);
  }

  .spinner {
    position: relative;
    top: 35%;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #0000009a;
    z-index: 1000000;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #00000000;
    z-index: 1000000;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #000000;
    z-index: 1000000;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .headers3 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #00000000;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    width: 90%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .rightImg {
    width: 28px;
    height: 28px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    z-index: 1;
  }

  .left {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #3eec12;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #43c923, #287914);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 10px;
    box-shadow: 0 0 10px #56cc087e;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #f0f7da;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .allWrap2 {
    background: url('./assets/anon.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .wlMain {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.07326680672268904) 0%, rgba(0, 0, 0, 0.2721463585434174) 49%, rgba(0, 0, 0, 0.8127626050420168) 100%), url('./assets/dataserver.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  #container2 {
    background-color: #007fc800;
    color: #0fce0f;
    width: 100%;
    opacity: 0.35;
    position: absolute;
    height: 565vh;
  }

  .light2 {
    height: 565vh;

  }

  .cont {

    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    background-size: cover;
  }

  .cont2 {
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;

      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;

      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }
  }

  .introduction {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .logo {
    width: 80px;
    height: 80px;
    border-radius: 100px;
    z-index: 5;

  }

  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 30px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
    font-family: 'Matrix', sans-serif;
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 35px;
    color: white;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 15px;
    font-family: 'Matrix', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    margin-top: 7%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://img.freepik.com/free-vector/stage-illuminated-by-spotlights_107791-928.jpg?w=740&t=st=1683982217~exp=1683982817~hmac=0f871c85d3f1a98262cfca6dc3abcff569a607560ea2e5da5691c0e9f2fcefd5') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Footer {
    margin-top: 1500px;
    padding-bottom: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transition: 0.5s;
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }


  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 25px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    color: #ffffff;
    text-align: center;
    text-shadow: 0 0 10px #68ff4e;
  }

  .conT2 {
    font-size: 75px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .storyCon {
    font-size: 16px;
    width: 55%;
    color: white;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;

  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    z-index: 1;

  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 28%;
    z-index: 10000000;
    font-weight: 500;
    position: absolute;
    margin-left: -20%;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 50000000000;
    position: absolute;
    margin-top: 30%;
    margin-left: -23%;
    width: 10%;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10;
    margin-top: 2%;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    margin-left: 2%;
    margin-right: 2%;
    border: none;
    padding: 10px;
    z-index: 900000000;
    cursor: pointer;
    background-color: #3a9ae800;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
    display: block;
    width: 35%;
    position: absolute;
  }

  .btnfos-0-2 {
    margin-left: 2%;
    margin-right: 2%;
    border: none;
    padding: 10px;
    z-index: 900000000;
    cursor: pointer;
    background-color: #3a9ae800;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
    display: block;
    width: 35%;
    position: absolute;
  }

  .nftamount {
    color: #ffffff;
    font-size: 2.5rem;
    z-index: 100000000000000000;
    text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #49ff18, 0 0 30px #49FF18, 0 0 40px #49FF18, 0 0 55px #49FF18, 0 0 75px #49ff18;
    position: absolute;
    margin-left: 7%;
    margin-top: -10%;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #24d600 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #252525;
    color: #98f595;
    padding: 10px;
    font-size: 100%;
    width: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.801);
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-left: -19.5%;
    margin-top: 36%;
    position: absolute;
    z-index: 10000000;
  }

  .hackersMobile {
    display: none;
  }

  .mintBtn {
    margin-left: -22.5%;
    margin-top: 34%;
    width: 13%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  .mintBtnHover {
    display: none;
    margin-left: -22.5%;
    margin-top: 34%;
    width: 13%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  .mintbuttondiv:hover .mintBtnHover {
    display: inline-block;
  }

  .btnfos-0-3:hover {
    background-color: #21af1b;
    color: white;
  }

  .btnfos-0-3:active {
    background-color: #10800c;
    transform: translateY(4px);
  }

  .btnfos-0-4 {
    background-color: #252525;
    color: #98f595;
    padding: 10px;
    font-size: 100%;
    width: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.801);
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 35%;
    margin-left: -19.8%;
    position: absolute;
    display: block;
  }

  .btnfos-0-4:hover {
    background-color: #21af1b;
    color: white;
  }

  .btnfos-0-4:active {
    background-color: #10800c;
    transform: translateY(4px);
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .formMain2 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(0, 0, 0);
    padding: 50px;
    border: #8afd8a 2px dashed;
    box-shadow: 0 0 20px #56cc087e;
    animation: popupAnimation 0.5s ease forwards;
  }

  .loading-bar {
    width: 400px;
    height: 20px;
    background-color: #f0f0f0;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
  }

  .loading-bar:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    background-color: #0fce0f;
    animation: loading 2s linear infinite;
  }


  .success-message {
    color: #50ec30;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .pepe {
    text-align: center;
    color: #0fce0f;
    margin-top: 20px;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    z-index: 10000000;
    color: white;
  }

  .loadText {
    text-align: center;
    color: #0fce0f;
    font-size: 30px;
    z-index: 100000000000000000;

  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;

  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(40, 141, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(0, 139, 42),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(40, 141, 0),
        .5em 0 0 rgb(40, 141, 0);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Anton', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 18px;
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 2rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #fbe8f300;
    border: 2px rgb(160, 160, 160) solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    text-shadow: 0 0 10px #a2ec95;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-shadow: 0 0 10px #7cb100;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  /*Matrix*/
  #container {
    background-color: #007fc800;
    color: #0fce0f;
    width: 100%;
    height: 100vh;
    z-index: 1000000000000000000000000000000000000;

  }

  canvas {
    background-color: rgba(0, 0, 0, 0);
  }

  .conatinerC {
    z-index: 100000000000000000000;
  }

  .hackers {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
  }

  .mintArea {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .code {
    width: 300px;
  }

  .errorCode {
    font-size: 30px;
    color: white;
    z-index: 10;
    text-align: center;
    margin-top: 21%;
    margin-left: 1%;
    text-transform: uppercase;
  }


  .glitch2-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    margin-top: 32%;
    margin-left: -66.4%;
    width: 100%;
  }

  .glitch2 {
    color: white;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    display: flex;
    justify-content: center;
    text-transform: upercase;
    position: absolute;
    display: inline-block;
    font-size: 1.3rem;
    color: white;
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
    line-height: 35px;

  }

  .glitch2::before,
  .glitch2::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch2::before {
    left: 2px;
    text-shadow: -2px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch2::after {
    left: -2px;
    text-shadow: -2px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  .pill {
    width: 6%;
    z-index: 10000000;
    margin-top: 36%;
    margin-left: -78.5%;
    position: absolute;
    cursor: pointer;
    animation: floating 3.5s ease-in-out infinite;
  }

  .pill-2 {
    width: 6%;
    z-index: 10000000;
    margin-top: 40%;
    margin-left: -78.5%;
    position: absolute;
    cursor: pointer;
    animation: floating2A 4s ease-in-out infinite;

  }

  .pillSpan:hover {
    transition: transform .2s ease-in-out;
  }

  .pill:hover {
    animation: none;
    /* Disable the floating animation on hover */
    transform: scale(1.3) rotate(5deg);
  }

  .pill-2:hover {
    animation: none;
    /* Disable the floating animation on hover */
    transform: scale(1.3) rotate(5deg);
  }

  @keyframes floating {
    0% {
      transform: translateY(0) rotate(-10deg);
    }

    50% {
      transform: translateY(-10px) rotate(-20deg);
    }

    100% {
      transform: translateY(0) rotate(-10deg);
    }
  }

  @keyframes floating2A {
    0% {
      transform: translateY(0) rotate(10deg);
    }

    50% {
      transform: translateY(-10px) rotate(20deg);
    }

    100% {
      transform: translateY(0) rotate(10deg);
    }
  }

  .musicOn {
    position: absolute;
    z-index: 1;
    width: 15%;
    margin-top: 38.5%;
    margin-left: 86%;
    cursor: pointer;
  }

  .hl {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
  }

  .audioLogo {
    width: 9%;
    position: absolute;
    z-index: 6;
    margin-top: 46%;
    margin-left: -12%;
  }

  .audioLogo2 {
    width: 105px;
    height: 105px;
    z-index: 1;
    position: absolute;
    margin-left: 3%;
    margin-top: -3%;
  }

  .playBtn {
    width: 18px;
    height: 18px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    margin-left: 25px;
    margin-right: 25px;
  }

  .playBtn2 {
    width: 16px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .playBtn:hover {
    transform: scale(1.2);
  }

  .playBtn2:hover {
    transform: scale(1.2);
  }

  .audioMain2-2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 7px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    width: 43%;
    position: absolute;
    bottom: 23%;
  }

  .audioMain2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 5px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    display: flex;
    width: 58%;
    margin-top: 47%;
    margin-left: -14%;
    z-index: 10;
  }

  .audioMainAb {
    position: fixed;
    margin-left: 38%;
    bottom: 30px;
    z-index: 50;
  }

  .audioMain2New {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    width: fit-content;
    padding-right: 10%;
    padding-left: 20%;
    padding-top: 7px;
    padding-bottom: 7px;
    position: relative;

  }


  .audioMain3 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
  }

  .songDetails {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
    margin-right: 20px;
  }

  .songName {
    color: rgb(255, 255, 255);
    font-size: 18px;
    width: 200px;
  }

  .artist {
    color: rgba(255, 255, 255, 0.726);
    font-size: 12px;
  }

  .music-bar {
    width: 55px;
    height: 55px;
    display: block;
    border-radius: 100%;
    background-color: ffffff;
    position: relative;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .bar {
    display: block;
    background-color: #00b4ff;
    height: 25px;
    width: 3px;
    text-indent: -9999px;
    position: absolute;
    top: 13px;
    -webkit-animation: bar 0ms -1000ms linear infinite alternate running;
    animation: bar 0ms -1000ms linear infinite alternate running;
  }

  .bar2 {
    display: block;
    background-color: #00b4ff;
    height: 5px;
    width: 3px;
    text-indent: -9999px;
    position: absolute;
    top: 36.3px;
  }

  @-webkit-keyframes bar {
    0% {
      transform: scale(1, 0.25);
    }

    25% {
      transform: scale(1, 0.5);
    }

    50% {
      transform: scale(1, 0.75);
    }

    75% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1.25);
    }
  }

  @keyframes bar {
    0% {
      transform: scale(1, 0.25);
    }

    25% {
      transform: scale(1, 0.5);
    }

    50% {
      transform: scale(1, 0.75);
    }

    75% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1.25);
    }
  }

  .bar-0 {
    left: 7px;
    -webkit-animation-duration: 337ms;
    animation-duration: 337ms;
  }

  .bar-1 {
    left: 16px;
    -webkit-animation-duration: 321ms;
    animation-duration: 321ms;
  }

  .bar-2 {
    left: 25px;
    -webkit-animation-duration: 353ms;
    animation-duration: 353ms;
  }

  .bar-3 {
    left: 34px;
    -webkit-animation-duration: 341ms;
    animation-duration: 341ms;
  }

  .bar-4 {
    left: 42px;
    -webkit-animation-duration: 327ms;
    animation-duration: 327ms;
  }

  .wlLogo {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .wl1 {
    font-size: 30px;
    color: white;
    text-align: center;
  }

  .wl2 {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.884);
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }

  .wlBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    color: white;
    background-color: #66eb1957;
    padding: 10px;
    width: 300px;
    border-image: linear-gradient(to right, #b0ff83, #80c73d);
    border-image-slice: 1;
    color: white;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    text-shadow: 0 0 10px #FFFFFF;
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 0 10px #9df8617e;

  }

  .wlBtn2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    color: white;
    background-color: #66eb1957;
    padding: 10px;
    width: 100%;
    border-image: linear-gradient(to right, #b0ff83, #80c73d);
    border-image-slice: 1;
    color: white;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    text-shadow: 0 0 10px #FFFFFF;
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 0 10px #9df8617e;

  }

  .wlBtn:hover {
    border-image: linear-gradient(to right, #e0f8d2, #acce8c);
    box-shadow: 0 0 10px #d4ffb77e;
    border-image-slice: 1;
    font-size: 20px;
  }

  .wlBtn2:hover {
    border-image: linear-gradient(to right, #e0f8d2, #acce8c);
    box-shadow: 0 0 10px #d4ffb77e;
    border-image-slice: 1;
    font-size: 20px;
  }

  .wlM2 {
    margin-top: 15vh;
    padding-bottom: 5vh;
  }

  .wlCard {
    position: absolute;
    width: 18%;
    z-index: 1;
    margin-left: 72%;
    margin-top: 7%;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.5s;
    transform-origin: center;
    transform: perspective(800px) rotateY(-20deg);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    filter: grayscale(1);

  }

  .wlCard:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
    transform: scale(1.1);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    filter: grayscale(0);

  }

  .wlCard:active {
    margin-top: 20px;
  }

  .pillWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: floatingUpAndDown 6.5s ease-in-out infinite;
    width: 25%;
  }

  @keyframes floatingUpAndDown {
    0% {
      transform: translateY(0) rotate(-10deg);
    }

    50% {
      transform: translateY(-20px) rotate(-12.5deg);
    }

    100% {
      transform: translateY(0) rotate(-10deg);
    }
  }

  .stickyPic {
    position: sticky;
    bottom: 23%;
    margin-left: 80%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
  }

  .wlInfo {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: auto;
  }

  .formCon div {
    color: white;
    margin-bottom: 10px;
  }

  .formCon {
    margin-bottom: 30px;
    color: white;
  }

  input {
    width: 300px;
    padding: 7px;
    border: none;
    background-color: #cccccc00;
    color: #c6f3c6;
    border: 1px solid white;
  }


  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 60%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(0, 0, 0);
    padding: 50px;
    border: #8afd8a 2px dashed;
    box-shadow: 0 0 20px #56cc087e;
    animation: popupAnimation 0.5s ease forwards;
  }

  @keyframes popupAnimation {
    0% {
      opacity: 0;
      transform: translate(-50%, -60%);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 5%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 20px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
    transition: transform .2s ease-in-out;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(178, 236, 139);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .glitchVaultH {
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-size: 50px;
    color: white;
    text-align: center;
    margin-top: 33vh;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.425);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 100%;
   }
  
  .glitchVaultH::before,
  .glitchVaultH::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .glitchVaultH::before {
    left: 2px;
    text-shadow: -2px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }
  
  .glitchVaultH::after {
    left: -2px;
    text-shadow: -2px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }
  .pepeImg {
    width: 120px;
    height: 47px;
    margin-right: 10px;
    margin-left: 5px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #43c923, #287914);
    border-image-slice: 1;
    box-shadow: 0 0 10px #56cc087e;

  }

  .audioMainAb2 {
    display: none;
  }

  .hackersMobile {
    display: none;
  }

  .bluePillDiv {
    width: 100%;
    height: 100vh;
    background: #272727;
    z-index: 1000000000000000;
    position: fixed;
  }

  .loading2 {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    z-index: 1000000000000000000000000000000000000;
    position: fixed;

  }

  .page-loader .txt {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 50%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    font-size: 20px;
    margin-left: 45%;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
  }

  .page-loaderNew .txt {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 50%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    font-size: 20px;
    margin-left: 45%;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
  }


  .back {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 90%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    margin-left: 1%;
    width: 50px;
    height: 50px;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .back:hover {
    transform: scale(1.2);
  }

  .spinner {
    position: relative;
    top: 35%;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #0000009a;
    z-index: 1000000;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #00000000;
    z-index: 1000000;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #000000;
    z-index: 1000000;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .headers3 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #00000000;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    width: 90%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .rightImg {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    z-index: 1;
  }

  .left {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #3eec12;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #43c923, #287914);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 10px;
    box-shadow: 0 0 10px #56cc087e;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #f0f7da;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .allWrap2 {
    background: url('./assets/anon.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .wlMain {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.07326680672268904) 0%, rgba(0, 0, 0, 0.2721463585434174) 49%, rgba(0, 0, 0, 0.8127626050420168) 100%), url('./assets/dataserver.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  #container2 {
    background-color: #007fc800;
    color: #0fce0f;
    width: 100%;
    opacity: 0.35;
    position: absolute;
    height: 520vh;
  }

  .light2 {
    height: 520vh;
  }

  .cont {

    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    background-size: cover;
  }

  .cont2 {
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;

      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;

      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }
  }

  .introduction {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .logo {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    z-index: 5;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 30px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
    font-family: 'Matrix', sans-serif;
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 40px;
    color: white;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 20px;
    font-family: 'Matrix', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    margin-top: 7%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://img.freepik.com/free-vector/stage-illuminated-by-spotlights_107791-928.jpg?w=740&t=st=1683982217~exp=1683982817~hmac=0f871c85d3f1a98262cfca6dc3abcff569a607560ea2e5da5691c0e9f2fcefd5') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Footer {
    margin-top: 1500px;
    padding-bottom: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transition: 0.5s;
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }


  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 25px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    color: #ffffff;
    text-align: center;
    text-shadow: 0 0 10px #68ff4e;
  }

  .conT2 {
    font-size: 75px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .storyCon {
    font-size: 16px;
    width: 55%;
    color: white;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;

  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;

  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 28%;
    z-index: 10000000;
    font-weight: 500;
    position: absolute;
    margin-left: -20%;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 50000000000;
    position: absolute;
    margin-top: 30%;
    margin-left: -23%;
    width: 10%;
    background-color: red;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10;
    margin-top: 2%;
    background-color: #10800c;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    margin-left: 2%;
    margin-right: 2%;
    border: none;
    padding: 10px;
    z-index: 900000000;
    cursor: pointer;
    background-color: #3a9ae800;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
    display: block;
    width: 35%;
    position: absolute;
  }

  .nftamount {
    color: #ffffff;
    font-size: 3.2rem;
    z-index: 100000000000000000;
    text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #49ff18, 0 0 30px #49FF18, 0 0 40px #49FF18, 0 0 55px #49FF18, 0 0 75px #49ff18;
    position: absolute;
    margin-left: 7%;
    margin-top: -8%;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #24d600 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #252525;
    color: #98f595;
    padding: 10px;
    font-size: 100%;
    width: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.801);
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-left: -19.5%;
    margin-top: 36%;
    position: absolute;
    z-index: 10000000;
  }

  .mintBtn {
    margin-left: -22.5%;
    margin-top: 34%;
    width: 13%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  .mintBtnHover {
    display: none;
    margin-left: -22.5%;
    margin-top: 34%;
    width: 13%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  .mintbuttondiv:hover .mintBtnHover {
    display: inline-block;
  }

  .btnfos-0-3:hover {
    background-color: #21af1b;
    color: white;
  }

  .btnfos-0-3:active {
    background-color: #10800c;
    transform: translateY(4px);
  }

  .btnfos-0-4 {
    background-color: #252525;
    color: #98f595;
    padding: 10px;
    font-size: 100%;
    width: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.801);
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 35%;
    margin-left: -19.8%;
    position: absolute;
    display: block;
  }

  .btnfos-0-4:hover {
    background-color: #21af1b;
    color: white;
  }

  .btnfos-0-4:active {
    background-color: #10800c;
    transform: translateY(4px);
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
    background-color: red;

  }

  .formMain2 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(0, 0, 0);
    padding: 50px;
    border: #8afd8a 2px dashed;
    box-shadow: 0 0 20px #56cc087e;
    animation: popupAnimation 0.5s ease forwards;
  }

  .loading-bar {
    width: 400px;
    height: 20px;
    background-color: #f0f0f0;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
  }

  .loading-bar:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    background-color: #0fce0f;
    animation: loading 2s linear infinite;
  }


  .success-message {
    color: #50ec30;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .pepe {
    text-align: center;
    color: #0fce0f;
    margin-top: 20px;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    z-index: 10000000;
    color: white;
  }

  .loadText {
    text-align: center;
    color: #0fce0f;
    font-size: 30px;
    z-index: 100000000000000000;

  }

  .loadingText-2 {
    text-align: center;
    color: white;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .error-2 {
    text-align: center;
    color: #fc4741;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .success-2 {
    text-align: center;
    color: #50ec30;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;

  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(40, 141, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(0, 139, 42),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(40, 141, 0),
        .5em 0 0 rgb(40, 141, 0);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Anton', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 18px;
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 2rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #fbe8f300;
    border: 2px rgb(160, 160, 160) solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    text-shadow: 0 0 10px #a2ec95;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-shadow: 0 0 10px #7cb100;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  /*Matrix*/
  #container {
    background-color: #007fc800;
    color: #0fce0f;
    width: 100%;
    height: 100vh;
    z-index: 1000000000000000000000000000000000000;

  }

  canvas {
    background-color: rgba(0, 0, 0, 0);
  }

  .conatinerC {
    z-index: 100000000000000000000;
  }

  .hackers {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
  }

  .mintArea {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .code {
    width: 300px;
  }

  .errorCode {
    font-size: 30px;
    color: white;
    z-index: 10;
    text-align: center;
    margin-top: 21%;
    margin-left: 1%;
    text-transform: uppercase;
  }


  .glitch2-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    margin-top: 32%;
    margin-left: -66.2%;
    width: 100%;
  }

  .glitch2 {
    color: white;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    display: flex;
    justify-content: center;
    text-transform: upercase;
    position: absolute;
    display: inline-block;
    font-size: 1.6rem;
    color: white;
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
    line-height: 35px;

  }

  .glitch2::before,
  .glitch2::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch2::before {
    left: 2px;
    text-shadow: -2px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch2::after {
    left: -2px;
    text-shadow: -2px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  .pill {
    width: 6%;
    z-index: 10000000;
    margin-top: 36%;
    margin-left: -78.5%;
    position: absolute;
    cursor: pointer;
    animation: floating 3.5s ease-in-out infinite;
  }

  .pill-2 {
    width: 6%;
    z-index: 10000000;
    margin-top: 40%;
    margin-left: -78.5%;
    position: absolute;
    cursor: pointer;
    animation: floating2A 4s ease-in-out infinite;

  }

  .pillSpan:hover {
    transition: transform .2s ease-in-out;
  }

  .pill:hover {
    animation: none;
    /* Disable the floating animation on hover */
    transform: scale(1.3) rotate(5deg);
  }

  .pill-2:hover {
    animation: none;
    /* Disable the floating animation on hover */
    transform: scale(1.3) rotate(5deg);
  }

  @keyframes floating {
    0% {
      transform: translateY(0) rotate(-10deg);
    }

    50% {
      transform: translateY(-10px) rotate(-20deg);
    }

    100% {
      transform: translateY(0) rotate(-10deg);
    }
  }

  @keyframes floating2A {
    0% {
      transform: translateY(0) rotate(10deg);
    }

    50% {
      transform: translateY(-10px) rotate(20deg);
    }

    100% {
      transform: translateY(0) rotate(10deg);
    }
  }

  .musicOn {
    position: absolute;
    z-index: 1;
    width: 15%;
    margin-top: 38.5%;
    margin-left: 86%;
    cursor: pointer;
  }

  .hl {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
  }

  .audioLogo {
    width: 7%;
    position: absolute;
    z-index: 6;
    margin-top: 46%;
    margin-left: 1%;
  }

  .audioLogo2 {
    width: 105px;
    height: 105px;
    z-index: 1;
    position: absolute;
    margin-left: 3%;
    margin-top: -3%;
  }

  .playBtn {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    margin-left: 25px;
    margin-right: 25px;
  }

  .playBtn2 {
    width: 18px;
    height: 18px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .playBtn:hover {
    transform: scale(1.2);
  }

  .playBtn2:hover {
    transform: scale(1.2);
  }

  .audioMain2-2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 7px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    width: 43%;
    position: absolute;
    bottom: 23%;
  }

  .audioMain2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 7px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    display: flex;
    width: 43%;
    margin-top: 47%;
    z-index: 5;
  }

  .audioMainAb {
    position: fixed;
    margin-left: 50%;
    bottom: 30px;
    z-index: 50;
  }

  .audioMain2New {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-flow: row nowrap;
    display: flex;
    margin-right: auto;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    width: fit-content;
    padding-right: 10%;
    padding-left: 20%;
    padding-top: 7px;
    padding-bottom: 7px;
    position: relative;
  }


  .audioMain3 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
  }

  .songDetails {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
    margin-right: 20px;
  }

  .songName {
    color: rgb(255, 255, 255);
    font-size: 18px;
    width: 200px;
  }

  .artist {
    color: rgba(255, 255, 255, 0.726);
    font-size: 12px;
  }

  .music-bar {
    width: 55px;
    height: 55px;
    display: block;
    border-radius: 100%;
    background-color: ffffff;
    position: relative;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .bar {
    display: block;
    background-color: #00b4ff;
    height: 25px;
    width: 3px;
    text-indent: -9999px;
    position: absolute;
    top: 13px;
    -webkit-animation: bar 0ms -1000ms linear infinite alternate running;
    animation: bar 0ms -1000ms linear infinite alternate running;
  }

  .bar2 {
    display: block;
    background-color: #00b4ff;
    height: 5px;
    width: 3px;
    text-indent: -9999px;
    position: absolute;
    top: 36.3px;
  }

  @-webkit-keyframes bar {
    0% {
      transform: scale(1, 0.25);
    }

    25% {
      transform: scale(1, 0.5);
    }

    50% {
      transform: scale(1, 0.75);
    }

    75% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1.25);
    }
  }

  @keyframes bar {
    0% {
      transform: scale(1, 0.25);
    }

    25% {
      transform: scale(1, 0.5);
    }

    50% {
      transform: scale(1, 0.75);
    }

    75% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1.25);
    }
  }

  .bar-0 {
    left: 7px;
    -webkit-animation-duration: 337ms;
    animation-duration: 337ms;
  }

  .bar-1 {
    left: 16px;
    -webkit-animation-duration: 321ms;
    animation-duration: 321ms;
  }

  .bar-2 {
    left: 25px;
    -webkit-animation-duration: 353ms;
    animation-duration: 353ms;
  }

  .bar-3 {
    left: 34px;
    -webkit-animation-duration: 341ms;
    animation-duration: 341ms;
  }

  .bar-4 {
    left: 42px;
    -webkit-animation-duration: 327ms;
    animation-duration: 327ms;
  }

  .wlLogo {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .wl1 {
    font-size: 30px;
    color: white;
    text-align: center;
  }

  .wl2 {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.884);
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }

  .wlBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    color: white;
    background-color: #66eb1957;
    padding: 10px;
    width: 300px;
    border-image: linear-gradient(to right, #b0ff83, #80c73d);
    border-image-slice: 1;
    color: white;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    text-shadow: 0 0 10px #FFFFFF;
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 0 10px #9df8617e;

  }

  .wlBtn2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    color: white;
    background-color: #66eb1957;
    padding: 10px;
    width: 100%;
    border-image: linear-gradient(to right, #b0ff83, #80c73d);
    border-image-slice: 1;
    color: white;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    text-shadow: 0 0 10px #FFFFFF;
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 0 10px #9df8617e;

  }


  .wlBtn:hover {
    border-image: linear-gradient(to right, #e0f8d2, #acce8c);
    box-shadow: 0 0 10px #d4ffb77e;
    border-image-slice: 1;
    font-size: 20px;
  }

  .wlBtn2:hover {
    border-image: linear-gradient(to right, #e0f8d2, #acce8c);
    box-shadow: 0 0 10px #d4ffb77e;
    border-image-slice: 1;
    font-size: 20px;
  }

  .wlM2 {
    margin-top: 15vh;
    padding-bottom: 5vh;
  }

  .wlCard {
    position: absolute;
    width: 18%;
    z-index: 1;
    margin-left: 72%;
    margin-top: 7%;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.5s;
    transform-origin: center;
    transform: perspective(800px) rotateY(-20deg);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    filter: grayscale(1);

  }

  .wlCard:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
    transform: scale(1.1);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    filter: grayscale(0);

  }

  .wlCard:active {
    margin-top: 20px;
  }

  .pillWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: floatingUpAndDown 6.5s ease-in-out infinite;
    width: 25%;
  }

  @keyframes floatingUpAndDown {
    0% {
      transform: translateY(0) rotate(-10deg);
    }

    50% {
      transform: translateY(-20px) rotate(-12.5deg);
    }

    100% {
      transform: translateY(0) rotate(-10deg);
    }
  }

  .stickyPic {
    position: sticky;
    bottom: 23%;
    margin-left: 80%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
  }

  .wlInfo {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: auto;
  }

  .formCon div {
    color: white;
    margin-bottom: 10px;
  }

  .formCon {
    margin-bottom: 30px;
    color: white;
  }

  input {
    width: 300px;
    padding: 7px;
    border: none;
    background-color: #cccccc00;
    color: #c6f3c6;
    border: 1px solid white;
  }


  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 60%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(0, 0, 0);
    padding: 50px;
    border: #8afd8a 2px dashed;
    box-shadow: 0 0 20px #56cc087e;
    animation: popupAnimation 0.5s ease forwards;
  }

  @keyframes popupAnimation {
    0% {
      opacity: 0;
      transform: translate(-50%, -60%);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 5%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 20px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
    transition: transform .2s ease-in-out;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(178, 236, 139);
  }
}

@media screen and (min-width: 1920px) {

  .glitchVaultH {
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-size: 80px;
    color: white;
    text-align: center;
    margin-top: 35vh;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.425);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 100%;
   }
  
  .glitchVaultH::before,
  .glitchVaultH::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .glitchVaultH::before {
    left: 4px;
    text-shadow: -4px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }
  
  .glitchVaultH::after {
    left: -4px;
    text-shadow: -4px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }
  
  .loadingText-2 {
    text-align: center;
    color: white;
    font-size: 24px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .success-2 {
    text-align: center;
    color: #50ec30;
    font-size: 24px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .error-2 {
    text-align: center;
    color: #fc4741;
    font-size: 24px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .pepeImg {
    width: 160px;
    height: 63px;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10;
    margin-left: 5px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #43c923, #287914);
    border-image-slice: 1;
    box-shadow: 0 0 20px #56cc087e;

  }

  .audioMainAb2 {
    display: none;
  }

  .hackersMobile {
    display: none;
  }

  .bluePillDiv {
    width: 100%;
    height: 100vh;
    background: #272727;
    z-index: 1000000000000000;
    position: fixed;
  }

  .loading2 {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    z-index: 1000000000000000000000000000000000000;
    position: fixed;

  }

  .page-loader .txt {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 50%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    font-size: 25px;
    margin-left: 45%;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
  }

  .page-loaderNew .txt {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 50%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    font-size: 25px;
    margin-left: 45%;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
  }


  .back {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 90%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    margin-left: 1%;
    width: 70px;
    height: 70px;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .back:hover {
    transform: scale(1.2);
  }

  .spinner {
    position: relative;
    top: 35%;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #0000009a;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #00000000;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #000000;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers3 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #00000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    width: 90%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .rightImg {
    width: 45px;
    height: 45px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
    z-index: 1;
  }

  .left {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #3eec12;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 15px;
    font-size: 25px;
    padding-left: 40px;
    padding-right: 40px;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #43c923, #287914);
    border-image-slice: 1;
    color: white;
    letter-spacing: 4px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 15px;
    box-shadow: 0 0 10px #56cc087e;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #f0f7da;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .allWrap2 {
    background: url('./assets/anon.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .wlMain {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.07326680672268904) 0%, rgba(0, 0, 0, 0.2721463585434174) 49%, rgba(0, 0, 0, 0.8127626050420168) 100%), url('./assets/dataserver.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  #container2 {
    background-color: #007fc800;
    color: #0fce0f;
    width: 100%;
    opacity: 0.35;
    position: absolute;
    height: 380vh;
  }

  .light2 {
    height: 380vh;

  }

  .cont {

    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    background-size: cover;
  }

  .cont2 {
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;

      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;

      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }
  }

  .introduction {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .logo {
    width: 140px;
    height: 140px;
    border-radius: 100px;
    z-index: 5;

  }

  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 50px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
    font-family: 'Matrix', sans-serif;
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 55px;
    color: white;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 20px;
    font-family: 'Matrix', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 3px;
    text-shadow: -3px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -3px;
    text-shadow: -3px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    margin-top: 7%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://img.freepik.com/free-vector/stage-illuminated-by-spotlights_107791-928.jpg?w=740&t=st=1683982217~exp=1683982817~hmac=0f871c85d3f1a98262cfca6dc3abcff569a607560ea2e5da5691c0e9f2fcefd5') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Footer {
    margin-top: 1500px;
    padding-bottom: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transition: 0.5s;
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }


  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 35px;
    color: white;
    letter-spacing: 3px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    color: #ffffff;
    text-align: center;
    text-shadow: 0 0 10px #68ff4e;
  }

  .conT2 {
    font-size: 75px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .storyCon {
    font-size: 22px;
    width: 55%;
    color: white;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;

  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    z-index: 1;

  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 28%;
    z-index: 10000000;
    font-weight: 500;
    position: absolute;
    margin-left: -20%;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 50000000000;
    position: absolute;
    margin-top: 30%;
    margin-left: -23%;
    width: 10%;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10;
    margin-top: 2%;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    margin-left: 2%;
    margin-right: 2%;
    border: none;
    padding: 15px;
    z-index: 900000000;
    cursor: pointer;
    background-color: #3a9ae800;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
    display: block;
    width: 35%;
    position: absolute;
  }

  .nftamount {
    color: #ffffff;
    font-size: 4.5rem;

    z-index: 100000000000000000;
    text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #49ff18, 0 0 30px #49FF18, 0 0 40px #49FF18, 0 0 55px #49FF18, 0 0 75px #49ff18;
    position: absolute;
    margin-left: 7%;
    margin-top: -9%;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #24d600 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #252525;
    color: #98f595;
    padding: 10px;
    font-size: 100%;
    width: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.801);
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-left: -19.5%;
    margin-top: 36%;
    position: absolute;
    z-index: 10000000;
  }

  .mintBtn {
    margin-left: -22.5%;
    margin-top: 34%;
    width: 13%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  .mintBtnHover {
    display: none;
    margin-left: -22.5%;
    margin-top: 34%;
    width: 13%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  .mintbuttondiv:hover .mintBtnHover {
    display: inline-block;
  }

  .btnfos-0-3:hover {
    background-color: #21af1b;
    color: white;
  }

  .btnfos-0-3:active {
    background-color: #10800c;
    transform: translateY(4px);
  }

  .btnfos-0-4 {
    background-color: #252525;
    color: #98f595;
    padding: 10px;
    font-size: 100%;
    width: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.801);
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 35%;
    margin-left: -19.8%;
    position: absolute;
    display: block;
  }

  .btnfos-0-4:hover {
    background-color: #21af1b;
    color: white;
  }

  .btnfos-0-4:active {
    background-color: #10800c;
    transform: translateY(4px);
  }


  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .formMain2 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 60%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(0, 0, 0);
    padding: 50px;
    border: #8afd8a 2px dashed;
    box-shadow: 0 0 20px #56cc087e;
    animation: popupAnimation 0.5s ease forwards;
  }

  .loading-bar {
    width: 600px;
    height: 25px;
    background-color: #f0f0f0;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
  }

  .loading-bar:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    background-color: #0fce0f;
    animation: loading 2s linear infinite;
  }

  .loadFont {
    font-size: 25px;
  }

  .success-message {
    color: #50ec30;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 35px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 30px;
    margin-bottom: 0;
  }

  .pepe {
    text-align: center;
    color: #0fce0f;
    margin-top: 20px;
    font-size: 22px;
  }

  .errorMessage {
    font-size: 28px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    z-index: 10000000;
    color: white;
  }

  .loadText {
    text-align: center;
    color: #0fce0f;
    font-size: 20px;
    z-index: 100000000000000000;

  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;

  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(40, 141, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(0, 139, 42),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(40, 141, 0),
        .5em 0 0 rgb(40, 141, 0);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Anton', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 24px;
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 2rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #fbe8f300;
    border: 2px rgb(160, 160, 160) solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 24px;
    text-shadow: 0 0 10px #a2ec95;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-shadow: 0 0 10px #7cb100;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  /*Matrix*/
  #container {
    background-color: #007fc800;
    color: #0fce0f;
    width: 100%;
    height: 100vh;
    z-index: 1000000000000000000000000000000000000;

  }

  canvas {
    background-color: rgba(0, 0, 0, 0);
  }

  .conatinerC {
    z-index: 100000000000000000000;
  }

  .hackers {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
  }

  .mintArea {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .code {
    width: 300px;
  }

  .errorCode {
    font-size: 30px;
    color: white;
    z-index: 10;
    text-align: center;
    margin-top: 21%;
    margin-left: 1%;
    text-transform: uppercase;
  }


  .glitch2-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    margin-top: 32%;
    margin-left: -66.5%;
    width: 100%;
  }

  .glitch2 {
    color: white;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    display: flex;
    justify-content: center;
    text-transform: upercase;
    position: absolute;
    display: inline-block;
    font-size: 2.4rem;
    color: white;
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
    line-height: 35px;

  }

  .glitch2::before,
  .glitch2::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch2::before {
    left: 3px;
    text-shadow: -3px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch2::after {
    left: -3px;
    text-shadow: -3px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  .pill {
    width: 6%;
    z-index: 10000000;
    margin-top: 36%;
    margin-left: -78.5%;
    position: absolute;
    cursor: pointer;
    animation: floating 3.5s ease-in-out infinite;
  }

  .pill-2 {
    width: 6%;
    z-index: 10000000;
    margin-top: 40%;
    margin-left: -78.5%;
    position: absolute;
    cursor: pointer;
    animation: floating2A 4s ease-in-out infinite;

  }

  .pillSpan:hover {
    transition: transform .2s ease-in-out;
  }

  .pill:hover {
    animation: none;
    /* Disable the floating animation on hover */
    transform: scale(1.3) rotate(5deg);
  }

  .pill-2:hover {
    animation: none;
    /* Disable the floating animation on hover */
    transform: scale(1.3) rotate(5deg);
  }

  @keyframes floating {
    0% {
      transform: translateY(0) rotate(-10deg);
    }

    50% {
      transform: translateY(-10px) rotate(-20deg);
    }

    100% {
      transform: translateY(0) rotate(-10deg);
    }
  }

  @keyframes floating2A {
    0% {
      transform: translateY(0) rotate(10deg);
    }

    50% {
      transform: translateY(-10px) rotate(20deg);
    }

    100% {
      transform: translateY(0) rotate(10deg);
    }
  }

  .musicOn {
    position: absolute;
    z-index: 1;
    width: 15%;
    margin-top: 38.5%;
    margin-left: 86%;
    cursor: pointer;
  }

  .hl {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
  }

  .audioLogo {
    width: 7%;
    position: absolute;
    z-index: 6;
    margin-top: 46%;
    margin-left: 1%;
  }

  .audioLogo2 {
    width: 150px;
    height: 150px;
    z-index: 1;
    position: absolute;
    margin-left: 3%;
    margin-top: -3%;
  }

  .playBtn {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    margin-left: 30px;
    margin-right: 30px;
  }

  .playBtn2 {
    width: 27px;
    height: 27px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .playBtn:hover {
    transform: scale(1.2);
  }

  .playBtn2:hover {
    transform: scale(1.2);
  }

  .audioMain2-2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 7px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    width: 43%;
    position: absolute;
    bottom: 23%;
  }

  .audioMain2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 15px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    display: flex;
    width: 43%;
    margin-top: 47%;
  }

  .audioMainAb {
    position: fixed;
    margin-left: 50%;
    bottom: 50px;
    z-index: 50;
  }

  .audioMain2New {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-flow: row nowrap;
    display: flex;
    margin-right: auto;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    width: fit-content;
    padding-right: 10%;
    padding-left: 20%;
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
  }


  .audioMain3 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
  }

  .songDetails {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
    margin-right: 20px;
  }

  .songName {
    color: rgb(255, 255, 255);
    font-size: 27px;
    width: 300px;
  }

  .artist {
    color: rgba(255, 255, 255, 0.726);
    font-size: 18px;
  }

  .music-bar {
    width: 60px;
    height: 60px;
    display: block;
    border-radius: 100%;
    background-color: ffffff;
    position: relative;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .bar {
    display: block;
    background-color: #00b4ff;
    height: 28px;
    width: 5px;
    text-indent: -9999px;
    position: absolute;
    top: 13px;
    -webkit-animation: bar 0ms -1000ms linear infinite alternate running;
    animation: bar 0ms -1000ms linear infinite alternate running;
  }

  .bar2 {
    display: block;
    background-color: #00b4ff;
    height: 5px;
    width: 3px;
    text-indent: -9999px;
    position: absolute;
    top: 36.3px;
  }

  @-webkit-keyframes bar {
    0% {
      transform: scale(1, 0.25);
    }

    25% {
      transform: scale(1, 0.5);
    }

    50% {
      transform: scale(1, 0.75);
    }

    75% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1.25);
    }
  }

  @keyframes bar {
    0% {
      transform: scale(1, 0.25);
    }

    25% {
      transform: scale(1, 0.5);
    }

    50% {
      transform: scale(1, 0.75);
    }

    75% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1.25);
    }
  }

  .bar-0 {
    left: 7px;
    -webkit-animation-duration: 337ms;
    animation-duration: 337ms;
  }

  .bar-1 {
    left: 16px;
    -webkit-animation-duration: 321ms;
    animation-duration: 321ms;
  }

  .bar-2 {
    left: 25px;
    -webkit-animation-duration: 353ms;
    animation-duration: 353ms;
  }

  .bar-3 {
    left: 34px;
    -webkit-animation-duration: 341ms;
    animation-duration: 341ms;
  }

  .bar-4 {
    left: 42px;
    -webkit-animation-duration: 327ms;
    animation-duration: 327ms;
  }

  .wlLogo {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .wl1 {
    font-size: 45px;
    color: white;
    text-align: center;
  }

  .wl2 {
    font-size: 23px;
    color: rgba(255, 255, 255, 0.884);
    text-align: center;
    margin-top: 35px;
    margin-bottom: 35px;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }

  .wlBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    color: white;
    background-color: #66eb1957;
    padding: 15px;
    width: 400px;
    border-image: linear-gradient(to right, #b0ff83, #80c73d);
    border-image-slice: 1;
    border-width: 3px;
    color: white;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    text-shadow: 0 0 10px #FFFFFF;
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 0 10px #9df8617e;
    font-size: 24px;
  }

  .wlBtn2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    color: white;
    background-color: #66eb1957;
    padding: 15px;
    width: 100%;
    border-image: linear-gradient(to right, #b0ff83, #80c73d);
    border-image-slice: 1;
    border-width: 3px;
    color: white;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    text-shadow: 0 0 10px #FFFFFF;
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 0 10px #9df8617e;
    font-size: 24px;
  }

  .wlBtn:hover {
    border-image: linear-gradient(to right, #e0f8d2, #acce8c);
    box-shadow: 0 0 10px #d4ffb77e;
    border-image-slice: 1;
    border-width: 3px;
    font-size: 28px;
  }

  .wlBtn2:hover {
    border-image: linear-gradient(to right, #e0f8d2, #acce8c);
    box-shadow: 0 0 10px #d4ffb77e;
    border-image-slice: 1;
    border-width: 3px;
    font-size: 28px;
  }

  .wlM2 {
    margin-top: 15vh;
    padding-bottom: 5vh;
  }

  .wlCard {
    position: absolute;
    width: 18%;
    z-index: 1;
    margin-left: 72%;
    margin-top: 7%;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.5s;
    transform-origin: center;
    transform: perspective(800px) rotateY(-20deg);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    filter: grayscale(1);

  }

  .wlCard:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
    transform: scale(1.1);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    filter: grayscale(0);

  }

  .wlCard:active {
    margin-top: 20px;
  }

  .pillWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: floatingUpAndDown 6.5s ease-in-out infinite;
    width: 25%;
  }

  @keyframes floatingUpAndDown {
    0% {
      transform: translateY(0) rotate(-10deg);
    }

    50% {
      transform: translateY(-20px) rotate(-12.5deg);
    }

    100% {
      transform: translateY(0) rotate(-10deg);
    }
  }

  .stickyPic {
    position: sticky;
    bottom: 23%;
    margin-left: 80%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
  }

  .wlInfo {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: auto;
  }

  .formCon div {
    color: white;
    margin-bottom: 10px;
    font-size: 22px;
  }

  .formCon {
    margin-bottom: 40px;
    color: white;
  }

  input {
    width: 400px;
    padding: 10px;
    border: none;
    background-color: #cccccc00;
    color: #c6f3c6;
    border: 1px solid white;
    font-size: 20px;
  }


  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 60%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(0, 0, 0);
    padding: 55px;
    border: #8afd8a 3px dashed;
    box-shadow: 0 0 20px #56cc087e;
    animation: popupAnimation 0.5s ease forwards;
  }

  @keyframes popupAnimation {
    0% {
      opacity: 0;
      transform: translate(-50%, -60%);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 5%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 25px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
    transition: transform .2s ease-in-out;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(178, 236, 139);
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .glitchVaultH {
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: 35vh;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.425);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 100%;
   }
  
  .glitchVaultH::before,
  .glitchVaultH::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .glitchVaultH::before {
    left: 4px;
    text-shadow: -4px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }
  
  .glitchVaultH::after {
    left: -4px;
    text-shadow: -4px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }
 
  .loadingText-2 {
    text-align: center;
    color: white;
    font-size: 28px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .success-2 {
    text-align: center;
    color: #50ec30;
    font-size: 28px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .error-2 {
    text-align: center;
    color: #fc4741;
    font-size: 28px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .pepeImg {
    width: 230px;
    height: 90px;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10;
    margin-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #43c923, #287914);
    border-image-slice: 1;
    box-shadow: 0 0 20px #56cc087e;

  }

  .audioMainAb2 {
    display: none;
  }

  .hackersMobile {
    display: none;
  }

  .bluePillDiv {
    width: 100%;
    height: 100vh;
    background: #272727;
    z-index: 1000000000000000;
    position: fixed;
  }

  .loading2 {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    z-index: 1000000000000000000000000000000000000;
    position: fixed;

  }

  .page-loader .txt {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 50%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    font-size: 35px;
    margin-left: 45%;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
  }

  .page-loaderNew .txt {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 50%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    font-size: 35px;
    margin-left: 45%;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
  }


  .back {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 90%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    margin-left: 1%;
    width: 100px;
    height: 100px;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .back:hover {
    transform: scale(1.2);
  }

  .spinner {
    position: relative;
    top: 35%;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #0000009a;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #00000000;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #000000;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers3 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #00000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    width: 90%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .rightImg {
    width: 55px;
    height: 55px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
    z-index: 1;
  }

  .left {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #3eec12;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 20px;
    font-size: 35px;
    padding-left: 50px;
    padding-right: 50px;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #43c923, #287914);
    border-image-slice: 1;
    color: white;
    letter-spacing: 6px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 25px;
    box-shadow: 0 0 10px #56cc087e;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #f0f7da;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .allWrap2 {
    background: url('./assets/anon.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .wlMain {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.07326680672268904) 0%, rgba(0, 0, 0, 0.2721463585434174) 49%, rgba(0, 0, 0, 0.8127626050420168) 100%), url('./assets/dataserver.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  #container2 {
    background-color: #007fc800;
    color: #0fce0f;
    width: 100%;
    opacity: 0.35;
    position: absolute;
    height: 375vh;
  }

  .light2 {
    height: 375vh;

  }

  .cont {

    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    background-size: cover;
  }

  .cont2 {
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;

      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;

      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }
  }

  .introduction {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .logo {
    width: 180px;
    height: 180px;
    border-radius: 100px;
    z-index: 5;

  }

  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 50px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
    font-family: 'Matrix', sans-serif;
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 75px;
    color: white;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 40px;
    font-family: 'Matrix', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 3px;
    text-shadow: -3px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -3px;
    text-shadow: -3px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    margin-top: 7%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://img.freepik.com/free-vector/stage-illuminated-by-spotlights_107791-928.jpg?w=740&t=st=1683982217~exp=1683982817~hmac=0f871c85d3f1a98262cfca6dc3abcff569a607560ea2e5da5691c0e9f2fcefd5') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Footer {
    margin-top: 1500px;
    padding-bottom: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transition: 0.5s;
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }


  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 45px;
    color: white;
    letter-spacing: 4px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    color: #ffffff;
    text-align: center;
    text-shadow: 0 0 10px #68ff4e;
  }

  .conT2 {
    font-size: 75px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .storyCon {
    font-size: 30px;
    width: 55%;
    color: white;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;

  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    z-index: 1;

  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 28%;
    z-index: 10000000;
    font-weight: 500;
    position: absolute;
    margin-left: -20%;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 50000000000;
    position: absolute;
    margin-top: 30%;
    margin-left: -23%;
    width: 10%;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10;
    margin-top: 2%;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    margin-left: 2%;
    margin-right: 2%;
    border: none;
    padding: 25px;
    z-index: 900000000;
    cursor: pointer;
    background-color: #3a9ae800;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
    display: block;
    width: 35%;
    position: absolute;
  }

  .nftamount {
    color: #ffffff;
    font-size: 6rem;
    z-index: 100000000000000000;
    text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #49ff18, 0 0 30px #49FF18, 0 0 40px #49FF18, 0 0 55px #49FF18, 0 0 75px #49ff18;
    position: absolute;
    margin-left: 7%;
    margin-top: -9%;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #24d600 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #252525;
    color: #98f595;
    padding: 10px;
    font-size: 100%;
    width: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.801);
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-left: -19.5%;
    margin-top: 36%;
    position: absolute;
    z-index: 10000000;
  }

  .mintBtn {
    margin-left: -22.5%;
    margin-top: 34%;
    width: 13%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  .mintBtnHover {
    display: none;
    margin-left: -22.5%;
    margin-top: 34%;
    width: 13%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  .mintbuttondiv:hover .mintBtnHover {
    display: inline-block;
  }

  .btnfos-0-3:hover {
    background-color: #21af1b;
    color: white;
  }

  .btnfos-0-3:active {
    background-color: #10800c;
    transform: translateY(4px);
  }

  .btnfos-0-4 {
    background-color: #252525;
    color: #98f595;
    padding: 10px;
    font-size: 100%;
    width: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.801);
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 35%;
    margin-left: -19.8%;
    position: absolute;
    display: block;
  }

  .btnfos-0-4:hover {
    background-color: #21af1b;
    color: white;
  }

  .btnfos-0-4:active {
    background-color: #10800c;
    transform: translateY(4px);
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .formMain2 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(0, 0, 0);
    padding: 50px;
    border: #8afd8a 4px dashed;
    box-shadow: 0 0 20px #56cc087e;
    animation: popupAnimation 0.5s ease forwards;
  }

  .loading-bar {
    width: 800px;
    height: 33px;
    background-color: #f0f0f0;
    position: relative;
    overflow: hidden;
    margin-top: 30px;
  }

  .loading-bar:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    background-color: #0fce0f;
    animation: loading 2s linear infinite;
  }

  .loadFont {
    font-size: 35px;
  }

  .success-message {
    color: #50ec30;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 50px;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 45px;
    margin-bottom: 0;
  }

  .pepe {
    text-align: center;
    color: #0fce0f;
    margin-top: 30px;
    font-size: 30px;
  }

  .errorMessage {
    font-size: 38px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    z-index: 10000000;
    color: white;
  }

  .loadText {
    text-align: center;
    color: #0fce0f;
    font-size: 20px;
    z-index: 100000000000000000;

  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;

  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(40, 141, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(0, 139, 42),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(40, 141, 0),
        .5em 0 0 rgb(40, 141, 0);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Anton', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 35px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 2rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #fbe8f300;
    border: 2px rgb(160, 160, 160) solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 33px;
    text-shadow: 0 0 10px #a2ec95;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-shadow: 0 0 10px #7cb100;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  /*Matrix*/
  #container {
    background-color: #007fc800;
    color: #0fce0f;
    width: 100%;
    height: 100vh;
    z-index: 1000000000000000000000000000000000000;

  }

  canvas {
    background-color: rgba(0, 0, 0, 0);
  }

  .conatinerC {
    z-index: 100000000000000000000;
  }

  .hackers {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
  }

  .mintArea {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .code {
    width: 300px;
  }

  .errorCode {
    font-size: 30px;
    color: white;
    z-index: 10;
    text-align: center;
    margin-top: 21%;
    margin-left: 1%;
    text-transform: uppercase;
  }


  .glitch2-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    margin-top: 32%;
    margin-left: -66.5%;
    width: 100%;
  }

  .glitch2 {
    color: white;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    display: flex;
    justify-content: center;
    text-transform: upercase;
    position: absolute;
    display: inline-block;
    font-size: 3.3rem;
    color: white;
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
    line-height: 35px;

  }

  .glitch2::before,
  .glitch2::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch2::before {
    left: 3px;
    text-shadow: -3px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch2::after {
    left: -3px;
    text-shadow: -3px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  .pill {
    width: 6%;
    z-index: 10000000;
    margin-top: 36%;
    margin-left: -78.5%;
    position: absolute;
    cursor: pointer;
    animation: floating 3.5s ease-in-out infinite;
  }

  .pill-2 {
    width: 6%;
    z-index: 10000000;
    margin-top: 40%;
    margin-left: -78.5%;
    position: absolute;
    cursor: pointer;
    animation: floating2A 4s ease-in-out infinite;

  }

  .pillSpan:hover {
    transition: transform .2s ease-in-out;
  }

  .pill:hover {
    animation: none;
    /* Disable the floating animation on hover */
    transform: scale(1.3) rotate(5deg);
  }

  .pill-2:hover {
    animation: none;
    /* Disable the floating animation on hover */
    transform: scale(1.3) rotate(5deg);
  }

  @keyframes floating {
    0% {
      transform: translateY(0) rotate(-10deg);
    }

    50% {
      transform: translateY(-10px) rotate(-20deg);
    }

    100% {
      transform: translateY(0) rotate(-10deg);
    }
  }

  @keyframes floating2A {
    0% {
      transform: translateY(0) rotate(10deg);
    }

    50% {
      transform: translateY(-10px) rotate(20deg);
    }

    100% {
      transform: translateY(0) rotate(10deg);
    }
  }

  .musicOn {
    position: absolute;
    z-index: 1;
    width: 15%;
    margin-top: 38.5%;
    margin-left: 86%;
    cursor: pointer;
  }

  .hl {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
  }

  .audioLogo {
    width: 7%;
    position: absolute;
    z-index: 6;
    margin-top: 46%;
    margin-left: 1%;
  }

  .audioLogo2 {
    width: 200px;
    height: 200px;
    z-index: 1;
    position: absolute;
    margin-left: 3%;
    margin-top: -3%;
  }

  .playBtn {
    width: 40px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    margin-left: 40px;
    margin-right: 40px;
  }

  .playBtn2 {
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .playBtn:hover {
    transform: scale(1.2);
  }

  .playBtn2:hover {
    transform: scale(1.2);
  }

  .audioMain2-2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 7px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    width: 43%;
    position: absolute;
    bottom: 23%;
  }

  .audioMain2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 20px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    display: flex;
    width: 43%;
    margin-top: 47%;
  }

  .audioMainAb {
    position: fixed;
    margin-left: 45%;
    bottom: 70px;
    z-index: 50;
  }

  .audioMain2New {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-flow: row nowrap;
    display: flex;
    margin-right: auto;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    width: fit-content;
    padding-right: 10%;
    padding-left: 20%;
    padding-top: 28px;
    padding-bottom: 28px;
    position: relative;
  }


  .audioMain3 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
  }

  .songDetails {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
    margin-right: 20px;
  }

  .songName {
    color: rgb(255, 255, 255);
    font-size: 34px;
    width: 450px;
  }

  .artist {
    color: rgba(255, 255, 255, 0.726);
    font-size: 24px;
  }

  .music-bar {
    width: 70px;
    height: 70px;
    display: block;
    border-radius: 100%;
    background-color: ffffff;
    position: relative;
    margin-left: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .bar {
    display: block;
    background-color: #00b4ff;
    height: 33px;
    width: 6px;
    text-indent: -9999px;
    position: absolute;
    top: 13px;
    -webkit-animation: bar 0ms -1000ms linear infinite alternate running;
    animation: bar 0ms -1000ms linear infinite alternate running;
  }

  .bar2 {
    display: block;
    background-color: #00b4ff;
    height: 6px;
    width: 3px;
    text-indent: -9999px;
    position: absolute;
    top: 64px;
  }

  @-webkit-keyframes bar {
    0% {
      transform: scale(1, 0.25);
    }

    25% {
      transform: scale(1, 0.5);
    }

    50% {
      transform: scale(1, 0.75);
    }

    75% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1.25);
    }
  }

  @keyframes bar {
    0% {
      transform: scale(1, 0.25);
    }

    25% {
      transform: scale(1, 0.5);
    }

    50% {
      transform: scale(1, 0.75);
    }

    75% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1.25);
    }
  }

  .bar-0 {
    left: 7px;
    -webkit-animation-duration: 337ms;
    animation-duration: 337ms;
  }

  .bar-1 {
    left: 16px;
    -webkit-animation-duration: 321ms;
    animation-duration: 321ms;
  }

  .bar-2 {
    left: 25px;
    -webkit-animation-duration: 353ms;
    animation-duration: 353ms;
  }

  .bar-3 {
    left: 34px;
    -webkit-animation-duration: 341ms;
    animation-duration: 341ms;
  }

  .bar-4 {
    left: 42px;
    -webkit-animation-duration: 327ms;
    animation-duration: 327ms;
  }

  .wlLogo {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .wl1 {
    font-size: 65px;
    color: white;
    text-align: center;
  }

  .wl2 {
    font-size: 30px;
    color: rgba(255, 255, 255, 0.884);
    text-align: center;
    margin-top: 35px;
    margin-bottom: 35px;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }

  .wlBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    color: white;
    background-color: #66eb1957;
    padding: 20px;
    width: 500px;
    border-image: linear-gradient(to right, #b0ff83, #80c73d);
    border-image-slice: 1;
    border-width: 5px;
    color: white;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    text-shadow: 0 0 10px #FFFFFF;
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 0 10px #9df8617e;
    font-size: 30px;
  }

  .wlBtn2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    color: white;
    background-color: #66eb1957;
    padding: 20px;
    width: 100%;
    border-image: linear-gradient(to right, #b0ff83, #80c73d);
    border-image-slice: 1;
    border-width: 5px;
    color: white;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    text-shadow: 0 0 10px #FFFFFF;
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 0 10px #9df8617e;
    font-size: 30px;
  }


  .wlBtn:hover {
    border-image: linear-gradient(to right, #e0f8d2, #acce8c);
    box-shadow: 0 0 10px #d4ffb77e;
    border-image-slice: 1;
    border-width: 5px;
    font-size: 35px;
  }

  .wlBtn2:hover {
    border-image: linear-gradient(to right, #e0f8d2, #acce8c);
    box-shadow: 0 0 10px #d4ffb77e;
    border-image-slice: 1;
    border-width: 5px;
    font-size: 35px;
  }

  .wlM2 {
    margin-top: 18vh;
    padding-bottom: 5vh;
  }

  .wlCard {
    position: absolute;
    width: 18%;
    z-index: 1;
    margin-left: 72%;
    margin-top: 7%;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.5s;
    transform-origin: center;
    transform: perspective(800px) rotateY(-20deg);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    filter: grayscale(1);

  }

  .wlCard:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
    transform: scale(1.1);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    filter: grayscale(0);

  }

  .wlCard:active {
    margin-top: 20px;
  }

  .pillWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: floatingUpAndDown 6.5s ease-in-out infinite;
    width: 25%;
  }

  @keyframes floatingUpAndDown {
    0% {
      transform: translateY(0) rotate(-10deg);
    }

    50% {
      transform: translateY(-20px) rotate(-12.5deg);
    }

    100% {
      transform: translateY(0) rotate(-10deg);
    }
  }

  .stickyPic {
    position: sticky;
    bottom: 23%;
    margin-left: 80%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
  }

  .wlInfo {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: auto;
  }

  .formCon div {
    color: white;
    margin-bottom: 10px;
    font-size: 28px;
  }

  .formCon {
    margin-bottom: 40px;
    color: white;
  }

  input {
    width: 500px;
    padding: 17px;
    border: none;
    background-color: #cccccc00;
    color: #c6f3c6;
    border: 1px solid white;
    font-size: 27px;
  }


  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 60%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(0, 0, 0);
    padding: 65px;
    border: #8afd8a 4px dashed;
    box-shadow: 0 0 20px #56cc087e;
    animation: popupAnimation 0.5s ease forwards;
  }

  @keyframes popupAnimation {
    0% {
      opacity: 0;
      transform: translate(-50%, -60%);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 5%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
    transition: transform .2s ease-in-out;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(178, 236, 139);
  }
}

@media screen and (min-width: 3840px) {
  .glitchVaultH {
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-size: 140px;
    color: white;
    text-align: center;
    margin-top: 35vh;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.425);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 100%;
   }
  
  .glitchVaultH::before,
  .glitchVaultH::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .glitchVaultH::before {
    left: 4px;
    text-shadow: -4px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }
  
  .glitchVaultH::after {
    left: -4px;
    text-shadow: -4px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }
  
  .loadingText-2 {
    text-align: center;
    color: white;
    font-size: 45px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .success-2 {
    text-align: center;
    color: #50ec30;
    font-size: 45px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .error-2 {
    text-align: center;
    color: #fc4741;
    font-size: 45px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .pepeImg {
    width: 355px;
    height: 139px;
    margin-right: 25px;
    margin-left: 20px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10;
    margin-left: 15px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10;
    border-width: 6px;
    border-style: solid;
    border-image: linear-gradient(to right, #43c923, #287914);
    border-image-slice: 1;
  }

  .hackersMobile {
    display: none;
  }

  .bluePillDiv {
    width: 100%;
    height: 100vh;
    background: #272727;
    z-index: 1000000000000000;
    position: fixed;
  }

  .loading2 {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    z-index: 1000000000000000000000000000000000000;
    position: fixed;

  }

  .page-loader .txt {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 50%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    font-size: 45px;
    margin-left: 45%;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
  }

  .page-loaderNew .txt {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 50%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    font-size: 55px;
    margin-left: 45%;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
  }


  .back {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
    text-align: center;
    top: 90%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    margin-left: 1%;
    width: 130px;
    height: 130px;
    display: flex;
    font-family: 'JetBrains Mono', 'monospace';
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .back:hover {
    transform: scale(1.2);
  }

  .spinner {
    position: relative;
    top: 35%;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #0000009a;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #00000000;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #000000;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers3 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #00000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    width: 90%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .rightImg {
    width: 88px;
    height: 88px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
    z-index: 1;
  }

  .left {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #3eec12;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 35px;
    font-size: 50px;
    padding-left: 60px;
    padding-right: 60px;
    border-width: 6px;
    border-style: solid;
    border-image: linear-gradient(to right, #43c923, #287914);
    border-image-slice: 1;
    color: white;
    letter-spacing: 8px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 35px;
    box-shadow: 0 0 10px #56cc087e;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #f0f7da;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .allWrap2 {
    background: url('./assets/anon.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .wlMain {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.07326680672268904) 0%, rgba(0, 0, 0, 0.2721463585434174) 49%, rgba(0, 0, 0, 0.8127626050420168) 100%), url('./assets/dataserver.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  #container2 {
    background-color: #007fc800;
    color: #0fce0f;
    width: 100%;
    opacity: 0.35;
    position: absolute;
    height: 375vh;
  }

  .light2 {
    height: 375vh;

  }

  .cont {

    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    background-size: cover;
  }

  .cont2 {
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;

      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;

      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: max-content;
      overflow: hidden;
    }
  }

  .introduction {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .logo {
    width: 300px;
    height: 300px;
    border-radius: 100px;
    margin-right: 80px;
    z-index: 5;

  }

  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 50px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
    font-family: 'Matrix', sans-serif;
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 110px;
    color: white;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 60px;
    font-family: 'Matrix', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 3px;
    text-shadow: -3px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -3px;
    text-shadow: -3px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    margin-top: 7%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://img.freepik.com/free-vector/stage-illuminated-by-spotlights_107791-928.jpg?w=740&t=st=1683982217~exp=1683982817~hmac=0f871c85d3f1a98262cfca6dc3abcff569a607560ea2e5da5691c0e9f2fcefd5') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Footer {
    margin-top: 1500px;
    padding-bottom: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transition: 0.5s;
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }


  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 85px;
    color: white;
    letter-spacing: 8px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    color: #ffffff;
    text-align: center;
    text-shadow: 0 0 10px #68ff4e;
  }

  .conT2 {
    font-size: 75px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .storyCon {
    font-size: 55px;
    width: 55%;
    color: white;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;

  }

  .storyCon p {
    color: white;
    margin-top: 60px;
    margin-bottom: 60px;
    text-align: left;
    z-index: 1;

  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 28%;
    z-index: 10000000;
    font-weight: 500;
    position: absolute;
    margin-left: -20%;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 50000000000;
    position: absolute;
    margin-top: 30%;
    margin-left: -23%;
    width: 10%;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10;
    margin-top: 2%;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    margin-left: 2%;
    margin-right: 2%;
    border: none;
    padding: 30px;
    z-index: 900000000;
    cursor: pointer;
    background-color: #3a9ae800;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
    display: block;
    width: 35%;
    position: absolute;
  }

  .nftamount {
    color: #ffffff;
    font-size: 9rem;
    z-index: 100000000000000000;
    text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #49ff18, 0 0 30px #49FF18, 0 0 40px #49FF18, 0 0 55px #49FF18, 0 0 75px #49ff18;
    position: absolute;
    margin-left: 7%;
    margin-top: -9%;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #24d600 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #252525;
    color: #98f595;
    padding: 10px;
    font-size: 100%;
    width: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.801);
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-left: -19.5%;
    margin-top: 36%;
    position: absolute;
    z-index: 10000000;
  }

  .mintBtn {
    margin-left: -22.5%;
    margin-top: 34%;
    width: 13%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  .mintBtnHover {
    display: none;
    margin-left: -22.5%;
    margin-top: 34%;
    width: 13%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  .mintbuttondiv:hover .mintBtnHover {
    display: inline-block;
  }

  .btnfos-0-3:hover {
    background-color: #21af1b;
    color: white;
  }

  .btnfos-0-3:active {
    background-color: #10800c;
    transform: translateY(4px);
  }

  .btnfos-0-4 {
    background-color: #252525;
    color: #98f595;
    padding: 10px;
    font-size: 100%;
    width: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.801);
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 35%;
    margin-left: -19.8%;
    position: absolute;
    display: block;
  }

  .btnfos-0-4:hover {
    background-color: #21af1b;
    color: white;
  }

  .btnfos-0-4:active {
    background-color: #10800c;
    transform: translateY(4px);
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .formMain2 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(0, 0, 0);
    padding: 50px;
    border: #8afd8a 8px dashed;
    box-shadow: 0 0 20px #56cc087e;
    animation: popupAnimation 0.5s ease forwards;
  }

  .loading-bar {
    width: 1000px;
    height: 45px;
    background-color: #f0f0f0;
    position: relative;
    overflow: hidden;
    margin-top: 60px;
  }

  .loading-bar:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    background-color: #0fce0f;
    animation: loading 2s linear infinite;
  }

  .loadFont {
    font-size: 55px;
  }

  .success-message h2 {
    font-size: 50px;
    margin-bottom: 30px;
    margin-top: 0;
  }


  .success-message h2 {
    font-size: 60px;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 55px;
    margin-bottom: 0;
  }

  .pepe {
    text-align: center;
    color: #0fce0f;
    margin-top: 60px;
    font-size: 45px;
  }

  .errorMessage {
    font-size: 60px;
    color: #fc4741;
    text-align: center;
    margin-top: 40px;
  }

  .loadingContainer {
    z-index: 10000000;
    color: white;
    margin-top: 60px;

  }

  .loadText {
    text-align: center;
    color: #0fce0f;
    font-size: 20px;
    z-index: 100000000000000000;

  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;

  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(40, 141, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(0, 139, 42),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(40, 141, 0),
        .5em 0 0 rgb(40, 141, 0);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Anton', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 35px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 2rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #fbe8f300;
    border: 2px rgb(160, 160, 160) solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 33px;
    text-shadow: 0 0 10px #a2ec95;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-shadow: 0 0 10px #7cb100;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  /*Matrix*/
  #container {
    background-color: #007fc800;
    color: #0fce0f;
    width: 100%;
    height: 100vh;
    z-index: 1000000000000000000000000000000000000;

  }

  canvas {
    background-color: rgba(0, 0, 0, 0);
  }

  .conatinerC {
    z-index: 100000000000000000000;
  }

  .hackers {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
  }

  .mintArea {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .code {
    width: 300px;
  }

  .errorCode {
    font-size: 30px;
    color: white;
    z-index: 10;
    text-align: center;
    margin-top: 21%;
    margin-left: 1%;
    text-transform: uppercase;
  }


  .glitch2-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    margin-top: 32%;
    margin-left: -66.5%;
    width: 100%;
  }

  .glitch2 {
    color: white;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    display: flex;
    justify-content: center;
    text-transform: upercase;
    position: absolute;
    display: inline-block;
    font-size: 5rem;
    color: white;
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
    line-height: 35px;

  }

  .glitch2::before,
  .glitch2::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch2::before {
    left: 3px;
    text-shadow: -3px 0 #379824;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch2::after {
    left: -3px;
    text-shadow: -3px 0 #7ad203;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch2-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch2-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  .pill {
    width: 6%;
    z-index: 10000000;
    margin-top: 36%;
    margin-left: -78.5%;
    position: absolute;
    cursor: pointer;
    animation: floating 3.5s ease-in-out infinite;
  }

  .pill-2 {
    width: 6%;
    z-index: 10000000;
    margin-top: 40%;
    margin-left: -78.5%;
    position: absolute;
    cursor: pointer;
    animation: floating2A 4s ease-in-out infinite;

  }

  .pillSpan:hover {
    transition: transform .2s ease-in-out;
  }

  .pill:hover {
    animation: none;
    /* Disable the floating animation on hover */
    transform: scale(1.3) rotate(5deg);
  }

  .pill-2:hover {
    animation: none;
    /* Disable the floating animation on hover */
    transform: scale(1.3) rotate(5deg);
  }

  @keyframes floating {
    0% {
      transform: translateY(0) rotate(-10deg);
    }

    50% {
      transform: translateY(-10px) rotate(-20deg);
    }

    100% {
      transform: translateY(0) rotate(-10deg);
    }
  }

  @keyframes floating2A {
    0% {
      transform: translateY(0) rotate(10deg);
    }

    50% {
      transform: translateY(-10px) rotate(20deg);
    }

    100% {
      transform: translateY(0) rotate(10deg);
    }
  }

  .musicOn {
    position: absolute;
    z-index: 1;
    width: 15%;
    margin-top: 38.5%;
    margin-left: 86%;
    cursor: pointer;
  }

  .hl {
    color: #74e461;
    text-shadow: 0 0 10px #a2ec95;
  }

  .audioLogo {
    width: 7%;
    position: absolute;
    z-index: 6;
    margin-top: 46%;
    margin-left: 1%;
  }

  .audioLogo2 {
    width: 300px;
    height: 300px;
    z-index: 1;
    position: absolute;
    margin-left: 3%;
    margin-top: -3%;
  }

  .playBtn {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    margin-left: 40px;
    margin-right: 40px;
  }

  .playBtn2 {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  .playBtn:hover {
    transform: scale(1.2);
  }

  .playBtn2:hover {
    transform: scale(1.2);
  }

  .audioMain2-2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 7px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    width: 43%;
    position: absolute;
    bottom: 23%;
  }

  .audioMain2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 35px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    display: flex;
    width: 43%;
    margin-top: 47%;
  }

  .audioMainAb {
    position: fixed;
    margin-left: 55%;
    bottom: 20px;
    z-index: 50;
  }

  .audioMainAb2 {
    display: none;
  }

  .audioMain2New {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-flow: row nowrap;
    display: flex;
    margin-right: auto;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    width: fit-content;
    padding-right: 10%;
    padding-left: 20%;
    padding-top: 45px;
    padding-bottom: 45px;
    position: relative;
    margin-bottom: 80px;
  }


  .audioMain3 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
  }

  .songDetails {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
    margin-right: 20px;
  }

  .songName {
    color: rgb(255, 255, 255);
    font-size: 50px;
    width: 550px;
  }

  .artist {
    color: rgba(255, 255, 255, 0.726);
    font-size: 35px;
  }

  .music-bar {
    width: 70px;
    height: 70px;
    display: block;
    border-radius: 100%;
    background-color: ffffff;
    position: relative;
    margin-left: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .bar {
    display: block;
    background-color: #00b4ff;
    height: 33px;
    width: 6px;
    text-indent: -9999px;
    position: absolute;
    top: 13px;
    -webkit-animation: bar 0ms -1000ms linear infinite alternate running;
    animation: bar 0ms -1000ms linear infinite alternate running;
  }

  .bar2 {
    display: block;
    background-color: #00b4ff;
    height: 6px;
    width: 3px;
    text-indent: -9999px;
    position: absolute;
    top: 64px;
  }

  @-webkit-keyframes bar {
    0% {
      transform: scale(1, 0.25);
    }

    25% {
      transform: scale(1, 0.5);
    }

    50% {
      transform: scale(1, 0.75);
    }

    75% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1.25);
    }
  }

  @keyframes bar {
    0% {
      transform: scale(1, 0.25);
    }

    25% {
      transform: scale(1, 0.5);
    }

    50% {
      transform: scale(1, 0.75);
    }

    75% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1.25);
    }
  }

  .bar-0 {
    left: 7px;
    -webkit-animation-duration: 337ms;
    animation-duration: 337ms;
  }

  .bar-1 {
    left: 16px;
    -webkit-animation-duration: 321ms;
    animation-duration: 321ms;
  }

  .bar-2 {
    left: 25px;
    -webkit-animation-duration: 353ms;
    animation-duration: 353ms;
  }

  .bar-3 {
    left: 34px;
    -webkit-animation-duration: 341ms;
    animation-duration: 341ms;
  }

  .bar-4 {
    left: 42px;
    -webkit-animation-duration: 327ms;
    animation-duration: 327ms;
  }

  .wlLogo {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .wl1 {
    font-size: 85px;
    color: white;
    text-align: center;
  }

  .wl2 {
    font-size: 45px;
    color: rgba(255, 255, 255, 0.884);
    text-align: center;
    margin-top: 45px;
    margin-bottom: 45px;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }

  .wlBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    color: white;
    background-color: #66eb1957;
    padding: 30px;
    width: 800px;
    border-image: linear-gradient(to right, #b0ff83, #80c73d);
    border-image-slice: 1;
    border-width: 8px;
    color: white;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    text-shadow: 0 0 10px #FFFFFF;
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 0 10px #9df8617e;
    font-size: 45px;
  }

  .wlBtn2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    color: white;
    background-color: #66eb1957;
    padding: 30px;
    width: 100%;
    border-image: linear-gradient(to right, #b0ff83, #80c73d);
    border-image-slice: 1;
    border-width: 8px;
    color: white;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    text-shadow: 0 0 10px #FFFFFF;
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 0 10px #9df8617e;
    font-size: 45px;
  }


  .wlBtn:hover {
    border-image: linear-gradient(to right, #e0f8d2, #acce8c);
    box-shadow: 0 0 10px #d4ffb77e;
    border-image-slice: 1;
    border-width: 8px;
    font-size: 50px;
  }

  .wlBtn2:hover {
    border-image: linear-gradient(to right, #e0f8d2, #acce8c);
    box-shadow: 0 0 10px #d4ffb77e;
    border-image-slice: 1;
    border-width: 8px;
    font-size: 50px;
  }

  .wlM2 {
    margin-top: 18vh;
    padding-bottom: 5vh;
  }

  .wlCard {
    position: absolute;
    width: 18%;
    z-index: 1;
    margin-left: 72%;
    margin-top: 7%;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.5s;
    transform-origin: center;
    transform: perspective(800px) rotateY(-20deg);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    filter: grayscale(1);

  }

  .wlCard:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
    transform: scale(1.1);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    filter: grayscale(0);

  }

  .wlCard:active {
    margin-top: 20px;
  }

  .pillWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: floatingUpAndDown 6.5s ease-in-out infinite;
    width: 25%;
  }

  @keyframes floatingUpAndDown {
    0% {
      transform: translateY(0) rotate(-10deg);
    }

    50% {
      transform: translateY(-20px) rotate(-12.5deg);
    }

    100% {
      transform: translateY(0) rotate(-10deg);
    }
  }

  .stickyPic {
    position: sticky;
    bottom: 23%;
    margin-left: 80%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
  }

  .wlInfo {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: auto;
  }

  .formCon div {
    color: white;
    margin-bottom: 30px;
    font-size: 45px;
  }

  .formCon {
    margin-bottom: 60px;
    color: white;
  }

  input {
    width: 100%;
    padding: 22px;
    border: none;
    background-color: #cccccc00;
    color: #c6f3c6;
    border: 2px solid white;
    font-size: 35px;
  }


  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 60%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(0, 0, 0);
    padding: 80px;
    border: #8afd8a 8px dashed;
    box-shadow: 0 0 20px #56cc087e;
    animation: popupAnimation 0.5s ease forwards;
  }

  @keyframes popupAnimation {
    0% {
      opacity: 0;
      transform: translate(-50%, -60%);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 5%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 50px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
    transition: transform .2s ease-in-out;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(178, 236, 139);
  }
}